import * as tslib_1 from "tslib";
import axios from "axios";
import CryptoJS from "crypto-js";
/**
 * Manejador General de dbFull
 */
export class dbFull {
    constructor() { }
    /**
     * Realiza una petición GET a los servidores de DbFull
     * @param request datos de la petición
     * @returns Retorna un array de los datos obtenidos
     */
    GET(request) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const requestEncrypted = dbFull.EncrypObj(request);
            let headers = Object.assign({ TokenAuthPlataform: dbFull.__privateData.tokendbFull, Authorization: dbFull.__privateData.authdbFUll }, requestEncrypted);
            const res = yield axios.get(dbFull.__privateData.url, { headers: this.TransformObjectAnyToString(headers) });
            return res.data;
        });
    }
    /**
     * Realiza una petición POST que te permite insertar nuevos elementos a una table
     * @param request datos de la petición
     * @param body cuerpo del dato a insertar
     * @returns Retorna el nuevo dato insertado
     */
    POST(request, body) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let { "x-keys-of-arrays": xKeysOfArray, "x-keys-to-add-id": xKeysToAddId, "x-relations": xRelations, type } = request, data = tslib_1.__rest(request, ["x-keys-of-arrays", "x-keys-to-add-id", "x-relations", "type"]);
            data = dbFull.EncrypObj(data);
            const headers = Object.assign({ TokenAuthPlataform: dbFull.__privateData.tokendbFull, Authorization: dbFull.__privateData.authdbFUll, "x-keys-of-arrays": xKeysOfArray || [], "x-keys-to-add-id": xKeysToAddId || [], "x-relations": xRelations || false }, data);
            const res = yield axios.post(dbFull.__privateData.url + type, body, { headers: this.TransformObjectAnyToString(headers) });
            return res.data;
        });
    }
    /**
     * Actualiza un dato ya existente en base de datos
     * @param request datos de la petición
     * @param body datos a actualizar (Todos no son obligatorios)
     * @returns Retorna la colunma actualizada (en la base de datos)
     */
    PUT(request, body) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let { "x-multiple-update": xMultipleUpdate, "x-elements-obj": xElementsObj, "x-attr-duplicate": xAttrDuplicate, type } = request, data = tslib_1.__rest(request, ["x-multiple-update", "x-elements-obj", "x-attr-duplicate", "type"]);
            data = dbFull.EncrypObj(data);
            const headers = Object.assign({ TokenAuthPlataform: dbFull.__privateData.tokendbFull, Authorization: dbFull.__privateData.authdbFUll, "x-multiple-update": JSON.stringify(xMultipleUpdate || false), "x-elements-obj": JSON.stringify(xElementsObj || []), "x-attr-duplicate": JSON.stringify(xAttrDuplicate || []) }, data);
            const res = yield axios.put(dbFull.__privateData.url + type, body, { headers: this.TransformObjectAnyToString(headers) });
            return res.data;
        });
    }
    /**
     * Permite realizar un query de manera aleatoria, siempre y cuando esta séa una expresión **SELECT**
     * @param request datos de la petición
     * @returns Retorna un resultado al azar según el query (NOTA: Tener cuidado)
     */
    GET_ANY_QUERY(request) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const headers = {
                TokenAuthPlataform: dbFull.__privateData.tokendbFull,
                Authorization: dbFull.__privateData.authdbFUll,
                db: dbFull.encrypt(request.db),
                type: dbFull.encrypt("any-queries"),
                "x-data-query": dbFull.encrypt(request.query || ""),
            };
            try {
                const res = yield axios.get(dbFull.__privateData.url, { headers: this.TransformObjectAnyToString(headers) });
                return res.data;
            }
            catch (err) {
                console.log(err.response.data);
                throw ("Error de get any query");
            }
        });
    }
    /**
     * Permite obtener la longitud de elementos encontrados, según séa la condición
     * @param request datos de la petición
     * @returns Retorna la longitud encontrada en base de datos
     */
    GET_LENGTH(request) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let query;
            if (request.where && Object.keys(request.where).length) {
                const whereQuery = Object.keys(request.where).map((key) => `${key}=${JSON.stringify(request.where[key])}`).join(" && ");
                query = `SELECT COUNT(*) FROM ${request.table} WHERE ${whereQuery}`;
            }
            else {
                query = `SELECT COUNT(*) FROM ${request.table}`;
            }
            const result = yield this.GET_ANY_QUERY({ db: request.db, query });
            const count = result.find(item => ("COUNT(*)" in item)) && result.find(item => ("COUNT(*)" in item))["COUNT(*)"];
            if (typeof count === "number")
                return count;
            else
                throw (new Error("No se logró obtener la longitud de la tabla: " + request.table + ", db: " + request.db));
        });
    }
    /**
     * Permite obtener sólamente los miembros necesarios de una tabla, según la condición
     * @param request datos de la petición
     * @returns Retorna los datos obteniidos, según la condición
     */
    SELECT(request) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let query;
            if (request.select && request.select.length) {
                const selectQuery = request.select.filter((item, index) => request.select.slice(index + 1).every(item2 => item !== item2)).join(", ");
                if (request.where && Object.keys(request.where).length) {
                    const whereQuery = Object.keys(request.where).map(key => `${key}=${JSON.stringify(request.where[key])}`).join(" && ");
                    query = `SELECT ${selectQuery} FROM ${request.table} WHERE ${whereQuery}`;
                }
                else {
                    query = `SELECT ${selectQuery} FROM ${request.table}`;
                }
            }
            else {
                if (request.where && Object.keys(request.where).length) {
                    const whereQuery = Object.keys(request.where).map(key => `${key}=${JSON.stringify(request.where[key])}`).join(" && ");
                    query = `SELECT * FROM ${request.table} WHERE ${whereQuery}`;
                }
                else {
                    query = `SELECT * FROM ${request.table}`;
                }
            }
            return yield this.GET_ANY_QUERY({ db: request.db, query: query });
        });
    }
    /**
     * Permite crear una subdivición del DbFull, que sólo pueda manejar una base de datos, y sus respectivas tablas (el **dbFullDataBase** generado
     * no tiene permitido acceder a otras base de datos pertenecientes a dbFull)
     * @param nameDB base de datos
     * @returns dbFull dedicado a manejar una base de datos específica
     */
    CreateChild(nameDB) {
        return dbFullDataBase.CreateByParent(nameDB, this);
    }
    /**
     * Transforma todos los miembros de un objeto en texto **(string)**
     * @param objTransform Objeto a transformar
     * @returns Un objeto con todas las variables miembros de tipo **string**
     */
    TransformObjectAnyToString(objTransform) {
        const objMaped = {};
        for (let keyObj in objTransform) {
            objMaped[keyObj] = typeof objTransform[keyObj] === "string" ? String(objTransform[keyObj]) : JSON.stringify(objTransform[keyObj]);
        }
        return objMaped;
    }
    /**
     * Permite encriptar un texto
     * @param str texto a encriptar
     * @returns texto encriptado
     */
    static encrypt(str) {
        let encrypted = CryptoJS.AES.encrypt(str, this.__privateData.key, {
            keySize: 16,
            mode: CryptoJS.mode.ECB,
            padding: CryptoJS.pad.Pkcs7,
        });
        return encrypted.toString();
    }
    /**
     * Permite desencriptar un texto encriptado
     * @param str texto a desencriptar
     * @returns Texto desencriptado
     */
    static decrypt(str) {
        let decrypted = CryptoJS.AES.decrypt(str, this.__privateData.key, {
            keySize: 16,
            mode: CryptoJS.mode.ECB,
            padding: CryptoJS.pad.Pkcs7,
        }).toString(CryptoJS.enc.Utf8);
        return decrypted.toString();
    }
    /**
     * Encripta un objeto (se permite aplicar recursividad; objeto dentro de otro objeto)
     * @param obj Objeto a encriptar
     * @returns Retorna una copia del objeto encriptado
     */
    static EncrypObj(obj) {
        const newObj = {};
        for (let keyName in obj) {
            if (obj[keyName] instanceof Object) {
                obj[keyName] = this.EncrypObj(obj[keyName]);
            }
            else
                newObj[keyName] = this.encrypt(String(obj[keyName]));
        }
        return newObj;
    }
}
/**
 * No se recomienda que esto esté aqui, (debe estar en el environment) pero si esto está aqui, permite que este fichero séa totalmente
 * idependiente
 */
dbFull.__privateData = {
    url: "https://dbfull.thomas-talk.me/api/",
    authdbFUll: 'Basic ' + btoa('Onur:L4V1d43NsuPl3N1tud**=BghYjLaQeTB'),
    key: 'T0rNaDoK4tr1Na?RTgcNmhKU=',
    tokendbFull: '81N2vjsIqq39qjGoEmDmMtjLqW7gLDA7vBV-Ffwuhwf-evejDaRGMrdSASny480GVOl7fcYfh21xfcpJWZ8VzQBHf0chPGOhyo9w3zJQ8OXEYGxwzxCU1gDplt3ebE4wDCkoujh4284bTkzz52AbNudtcR1HBq5_xU3mL5IJ4pqbeiFOJVa9',
};
/**
 * Subdivición de **class dbFull**, dedicado a manejar una única base de datos
 */
export class dbFullDataBase extends dbFull {
    /**
     * Constructor
     * @param db base de datos a manejar
     */
    constructor(db) {
        super();
        this.db = db;
        this.parent = null;
    }
    /**
     * Crea una instancia, asociado a su padre (esto es usado por **class dbFull**)
    */
    static CreateByParent(db, parent) {
        const dbDataBase = new dbFullDataBase(db);
        dbDataBase.parent = parent;
        return dbDataBase;
    }
    /**
     * Realiza una petición GET a los servidores de DbFull
     * @param request datos de la petición
     * @returns Retorna un array de los datos obtenidos
     */
    GET(request) {
        const _super = Object.create(null, {
            GET: { get: () => super.GET }
        });
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return yield _super.GET.call(this, Object.assign({}, request, { db: this.db }));
        });
    }
    /**
     * Realiza una petición POST que te permite insertar nuevos elementos a una table
     * @param request datos de la petición
     * @param body cuerpo del dato a insertar
     * @returns Retorna el nuevo dato insertado
     */
    POST(request, body) {
        const _super = Object.create(null, {
            POST: { get: () => super.POST }
        });
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return yield _super.POST.call(this, Object.assign({}, request, { db: this.db }), body);
        });
    }
    /**
     * Actualiza un dato ya existente en base de datos
     * @param request datos de la petición
     * @param body datos a actualizar (Todos no son obligatorios)
     * @returns Retorna la colunma actualizada (en la base de datos)
     */
    PUT(request, body) {
        const _super = Object.create(null, {
            PUT: { get: () => super.PUT }
        });
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return yield _super.PUT.call(this, Object.assign({}, request, { db: this.db }), body);
        });
    }
    /**
     * Permite realizar un query de manera aleatoria, siempre y cuando esta séa una expresión **SELECT**
     * @param request datos de la petición
     * @returns Retorna un resultado al azar según el query (NOTA: Tener cuidado)
     */
    GET_ANY_QUERY(request) {
        const _super = Object.create(null, {
            GET_ANY_QUERY: { get: () => super.GET_ANY_QUERY }
        });
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return yield _super.GET_ANY_QUERY.call(this, { db: this.db, query: request.query });
        });
    }
    /**
     * Permite obtener la longitud de elementos encontrados, según séa la condición
     * @param request datos de la petición
     * @returns Retorna la longitud encontrada en base de datos
     */
    GET_LENGTH(request) {
        const _super = Object.create(null, {
            GET_LENGTH: { get: () => super.GET_LENGTH }
        });
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return yield _super.GET_LENGTH.call(this, Object.assign({}, request, { db: this.db }));
        });
    }
    /**
     * Permite obtener sólamente los miembros necesarios de una tabla, según la condición
     * @param request datos de la petición
     * @returns Retorna los datos obteniidos, según la condición
     */
    SELECT(request) {
        const _super = Object.create(null, {
            SELECT: { get: () => super.SELECT }
        });
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return yield _super.SELECT.call(this, Object.assign({}, request, { db: this.db }));
        });
    }
    /**
     * Permite crear una subdivición del DbFull, que sólo pueda manejar una base de datos, y sus respectivas tablas (el **dbFullDataBase** generado
     * no tiene permitido acceder a otras base de datos pertenecientes a dbFull)
     * @param nameDB base de datos
     * @returns dbFull dedicado a manejar una base de datos específica
     */
    GetParent() {
        return this.parent || new dbFull();
    }
    /**
     * Permite crear una subdivición que tenga constrol sobre sí mismo a una tabla
     * @param nameTable nombre de la tabla
     * @returns retorna una instancia de la subdivición creada
     */
    CreateChild(nameTable) {
        return dbFullTable.CreateByParent(nameTable, this);
    }
}
/**
 * Subdivición de **class dbFullDataBase**, dedicado a manejar una única tabla perteneciente a su base de datos respectivo
 */
export class dbFullTable extends dbFullDataBase {
    constructor(db, table) {
        super(db);
        this.table = table;
        this.parent = null;
    }
    static CreateByParent(table, parent) {
        const dbDataBase = new dbFullTable(parent.db, table);
        dbDataBase.parent = parent;
        return dbDataBase;
    }
    GET(request) {
        const _super = Object.create(null, {
            GET: { get: () => super.GET }
        });
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return yield _super.GET.call(this, Object.assign({}, request, { table: this.table }));
        });
    }
    POST(request, body) {
        const _super = Object.create(null, {
            POST: { get: () => super.POST }
        });
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return yield _super.POST.call(this, Object.assign({}, request, { table: this.table }), body);
        });
    }
    PUT(request, body) {
        const _super = Object.create(null, {
            PUT: { get: () => super.PUT }
        });
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return yield _super.PUT.call(this, Object.assign({}, request, { table: this.table }), body);
        });
    }
    GET_ANY_QUERY(request) {
        const _super = Object.create(null, {
            GET_ANY_QUERY: { get: () => super.GET_ANY_QUERY }
        });
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return yield _super.GET_ANY_QUERY.call(this, request);
        });
    }
    GET_LENGTH(request) {
        const _super = Object.create(null, {
            GET_LENGTH: { get: () => super.GET_LENGTH }
        });
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return yield _super.GET_LENGTH.call(this, Object.assign({}, request, { table: this.table }));
        });
    }
    SELECT(request) {
        const _super = Object.create(null, {
            SELECT: { get: () => super.SELECT }
        });
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return yield _super.SELECT.call(this, Object.assign({}, request, { table: this.table }));
        });
    }
    GetParent() {
        return this.parent || new dbFullDataBase(this.db);
    }
}
