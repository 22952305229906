import { ChangeDetectorRef, Component, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { GetCategoriesInteface } from '../../../interfaces/product.interface';
import { ClubFibexFeedingService } from '../../../services/club-fibex-feeding.service';
import { Subscription } from 'rxjs';
import { FranquiciaInterface } from '../../../interfaces/getClubFibex.interface';

enum COUPON_MONEY_TYPE {
  DISCOUNT = 'discount',
  DOLLAR = 'dollar'
}

@Component({
  selector: 'app-filter',
  templateUrl: './filter.page.html',
  styleUrls: ['./filter.page.scss'],
})
export class FilterComponent implements OnInit, OnDestroy {

  groupCategory: string[] = [];
  activeCat: any[] = [];
  rate: number | string = 'all';
  price: string = 'all';
  rangePrices: string[] = ['1$', '10$', '50$', '100$', '+100$']
  stars: { id: number;url: string;urlBlanco: string;}[] = [];
  public categories: GetCategoriesInteface[] = [];
  public StarsSelected: any[] = [];
  minRange: string = '0';
  maxRange: string = '1000';
  public COUPON_MONEY_TYPES: typeof COUPON_MONEY_TYPE = COUPON_MONEY_TYPE
  public activeTabMoneyType: COUPON_MONEY_TYPE = COUPON_MONEY_TYPE.DISCOUNT;
  private paramSub: Subscription
  public companies: any[] = [];
  public currentURL: string;
  public showResetFilter: boolean = false;
  public showFranqModal: boolean = false;
  public showCompanyModal: boolean = false;
  public allFranqIndexed: {[key:string]: FranquiciaInterface}[] = [];//Franq indexed by its idFranquicia, for optimization
  public allCompaniesIndexed: {[key:string]:any}[] = [];
  public selectedCities: string[] = [];
  public selectedCompanies: string[] = [];
  activeOptionTab: boolean = false;
  public urlIsHome: boolean = false;
  constructor(
    private router : Router,
    private modalController : ModalController,
    private clubFibexService: ClubFibexFeedingService,
    private route: ActivatedRoute,
    private cdr: ChangeDetectorRef
  ) {
  }

  async ngOnInit() {

    try {
      this.cdr.detectChanges();
      this.categories = await this.clubFibexService.GetCategories()
      this.validateAllSelectedItems()
      let allFranq: any[] = await this.clubFibexService.getSucursales();
      let allCompanies: any[] = [];
    
      const urlTree = this.router.parseUrl(this.router.url);
      const urlWithoutQueryParams = urlTree.root.children['primary'].segments.map(it => it.path).join('/');
      this.currentURL = urlWithoutQueryParams;
      this.urlIsHome = this.currentURL.includes('home');
      if(this.selectedCompanies.length>0) allCompanies = await this.clubFibexService.SearchCompaniesById(this.selectedCompanies.map(value=>`'${value}'`).join(","));
      allFranq.map(franq => franq.Nombre = franq.Nombre.replace('FIBEX ', ''))

    } catch (error) {
      console.error(error)
    }
  }
  ngOnDestroy() {
    if (this.paramSub) this.paramSub.unsubscribe();
  }

  public validateAllSelectedItems = (): void => {
    try {
      const currentUrl = this.router.url;
      //ESTA VALIDACION PERMITIRA OCULTYAR LAS SECCIONES QUE NO SE RELACIONAN CON LA SECCION DE LAS MARCAS ALIADAS
      if(currentUrl.includes("/all-marks")) this.activeOptionTab = true;
      this.paramSub = this.route.queryParamMap.subscribe(async (data: Params) => {
        
        const {params} = data;
        if (params.qCategory) {
          const catIdSelected:string[] = params.qCategory.split("--");
          if (catIdSelected.length) {
            this.activeCat = this.categories.filter(cat => catIdSelected.includes(cat.idEmpCat) );
            this.showResetFilter = true;
          }
        }
        if (params.qPricing) {
          const range:string[] = params.qPricing.split('--');
          this.minRange = range[0];
          this.maxRange = range[1];
          if (this.minRange !== '0' || this.maxRange !== '1000') this.showResetFilter = true;
        }
        if (params.qDiscountType) {
          this.activeTabMoneyType = params.qDiscountType;
          if (this.activeTabMoneyType === COUPON_MONEY_TYPE.DOLLAR) this.showResetFilter = true;
        }
        //activeFranquicies
        if (params.qCities) {
          const franquicies = params.qCities.split("--");
           if (franquicies.length) this.showResetFilter = true;
          this.selectedCities =   franquicies;
      }
      if (params.qCompanies) {
        const companies = params.qCompanies.split("--");
         if (companies.length) this.showResetFilter = true;
        this.selectedCompanies =   companies;
    }
    
    if(params.qfilterCitiesByComp){
      
      this.activeOptionTab =params.qfilterCitiesByComp === 'true';
      
    }
      })
    } catch (error) {
      console.error(error)
    }
  }

  public addActiveCat(category: GetCategoriesInteface) {
    const index = this.activeCat.findIndex( cat => cat.idEmpCat === category.idEmpCat )
    if ( index === -1 ) {
       this.activeCat.push(category);
    } else {
      this.activeCat = this.activeCat.filter( cat => cat.idEmpCat !== category.idEmpCat)
    }
  }

  public toNumber = (text:string): number => {
    return Number(text)
  }
f
  public addActiveStar( star: any ) {
    let index = this.StarsSelected.findIndex(sta => sta.id === star.id)
    if ( index === -1 ) {
       this.StarsSelected.push(star);
    } else {
      this.StarsSelected = this.StarsSelected.filter( element => element.id !== star.id );
    }
  }

  private async initFn() {
    const categories:GetCategoriesInteface[] = await this.clubFibexService.GetCategories()
    this.groupCategory = categories.map((category:GetCategoriesInteface) => category.nombre);
  }

  onBackNavigation(){
    this.modalController.dismiss();
  }

  onNavigation(){
      const arrArg = []
      
      this.activeCat.forEach( element => {
        if( element.idEmpCat ) arrArg.push(element.idEmpCat); 
      });
      const qParam = arrArg.join('--')   
      this.modalController.dismiss();
      this.router.navigate(
        this.urlIsHome ? [this.currentURL] 
        : [`/club-fibex/${this.activeOptionTab ? 'all-marks' : 'product-list'}`],  
        { 
          queryParams: { 
            qCategory: arrArg.length ? qParam : null, 
            qPricing: this.minRange ? `${ this.minRange }--${ this.maxRange }` : null,
            qDiscountType: this.activeTabMoneyType,
            qCities: this.selectedCities.join('--'),
            qCompanies: this.selectedCompanies.join('--'),
            qfilterCitiesByComp: this.activeOptionTab,
            qTypeSearch: this.activeOptionTab ? 1 : 0
          },
          replaceUrl: true,
          queryParamsHandling: 'merge'
        }
      );
      // Promise.resolve().then(() => {
      //   window.location.reload()
      // })
  }

  setStarSelected( item: any ) {
    this.StarsSelected = item.id;
  }

  trigger(ev: any) {
    const { detail } = ev;
    const { value } = detail;
    this.minRange = `${value.lower}`;
    this.maxRange = `${value.upper}`;
  }

  public hasSelectedCat(category:string): boolean {
    return (this.activeCat.includes(category));
  }

  public hasSelectedStar(star:number): boolean {
    return (this.StarsSelected.includes(star));
  }

  /**
   * To transform the money type to all products
   */
  public transformCoupMoney = (moneyType:COUPON_MONEY_TYPE): void => {
    try {
      this.activeTabMoneyType = moneyType
    } catch (error) {
      console.error(error)
    }
  }
  
  public resetFilter = () => {
    try {
      this.activeTabMoneyType = COUPON_MONEY_TYPE.DISCOUNT;
      this.activeCat = []
      this.minRange = "0"
      this.maxRange = "1000"
      this.showResetFilter = false
      this.selectedCities = [];
      this.selectedCompanies= [];
    } catch (error) {
      console.error(error) 
    }
  }

  public handleShowFranqModal = (value:boolean) => {
    // if (value) this.selectedCities = [];
    this.showFranqModal = value
    this.cdr.detectChanges();
  }
  public handleShowCompanyModal = (value:boolean) => {
    // if (value) this.selectedCities = [];
    this.showCompanyModal = value;
    this.cdr.detectChanges();
  }

  public onFranqSelected = (franquicias: string[]) => {
    try {
      this.showFranqModal = false;
      this.selectedCities = franquicias;
      this.handleShowFranqModal(false)
    } catch (error) {
      console.error(error)
    }
  }
  public onCompaniesSelected = async (companies: string[]) => {
    try {
      let allCompanies: any[]=[];
      this.showCompanyModal = false;
      this.selectedCompanies=companies;
      if(this.selectedCompanies.length>0) allCompanies = await this.clubFibexService.SearchCompaniesById(companies.map(value=>`'${value}'`).join(","));

      for(let item of allCompanies){
        if(!this.allCompaniesIndexed[item.id]) this.allCompaniesIndexed[item.id] =item;
      }
      this.handleShowCompanyModal(false)
    } catch (error) {
      console.error(error)
    }
  }
  public onChangeMinRAnge($event:any){
    this.minRange = $event.target.value;
  }
  public onChangemaxRange($event:any){
    this.maxRange = $event.target.value;
    
  }
  itemAccordion( element: any, id: number ) {
    const elementId = element.getAttribute('data-faq')
    if( id === parseInt(elementId) ) element.classList.toggle('activa');

    const anwser = element.children[1];
    const ionIcon = element.querySelector('ion-icon');
    
    ionIcon.classList.toggle('rotate-icon');
    const height = anwser.scrollHeight;
    
    !anwser.style.maxHeight
      ? anwser.style.maxHeight = height + "px"
      : anwser.style.maxHeight = null;
  }
  setOPtionTab(value: boolean){
    this.activeOptionTab = value;
  }
}
