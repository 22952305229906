import { Routes } from '@angular/router';
import { AuthGuard } from './guards/authGuard/auth.guard';
import { HomeGuard } from './guards/homeGuard/home.guard';
const ɵ0 = () => import("./pages/walkthrough/walkthrough.module.ngfactory").then(m => m.WalkthroughPageModuleNgFactory), ɵ1 = () => import("./pages/new-login/new-login.module.ngfactory").then(m => m.NewLoginPageModuleNgFactory), ɵ2 = () => import("./pages/fibex-club/club-tabs/club-tabs.module.ngfactory").then(m => m.ClubTabsModuleNgFactory), ɵ3 = () => import("./pages/push-message/push-message.module.ngfactory").then(m => m.PushMessagePageModuleNgFactory);
const routes = [
    {
        path: '', loadChildren: ɵ0, canActivate: [AuthGuard],
    },
    // { path: 'home', loadChildren: () => import('./pages/home/home.module').then(m => m.HomePageModule), canActivate:[HomeGuard]},
    // { path: 'login', loadChildren: () => import('./pages/login/login.module').then(m => m.LoginPageModule), canActivate: [AuthGuard] },
    {
        path: 'new-login',
        loadChildren: ɵ1,
        canActivate: [AuthGuard]
    },
    {
        path: 'club-fibex',
        loadChildren: ɵ2,
        canActivate: [HomeGuard]
    },
    {
        path: 'push-message',
        loadChildren: ɵ3,
        canActivate: [HomeGuard]
    },
    // {
    //   path: 'club-fibex/club-iframe',
    //   pathMatch: 'full',
    //   loadChildren: () => import('./pages/fibex-club/club-iframe/club-iframe.module').then( m => m.ClubIframePageModule),
    //   canActivate: [HomeGuard]
    // },
    {
        path: '**', redirectTo: 'club-fibex/home'
    }
];
export class AppRoutingModule {
}
export { ɵ0, ɵ1, ɵ2, ɵ3 };
