import * as i0 from "@angular/core";
export class TranslateProvider {
    constructor() { }
    // Set the translations of the app.
    setTranslations(translations) {
        this.translations = translations;
    }
    getTranslations() {
        return this.translations;
    }
    // Get the translated string given the key based on the i18n .json file.
    get(key) {
        return this.translations[key];
    }
}
TranslateProvider.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function TranslateProvider_Factory() { return new TranslateProvider(); }, token: TranslateProvider, providedIn: "root" });
