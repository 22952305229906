import { Component, OnInit, Output } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { OptListInterface } from '../../../interfaces/opt-list.interface';

@Component({
  selector: 'app-opt-list',
  templateUrl: './opt-list.component.html',
  styleUrls: ['./opt-list.component.scss'],
})
export class OptListComponent implements OnInit {
  optionList: string[] | OptListInterface[] = ['Solicitar Soporte','Reclamo','Calificar'];

  constructor(private popCtrl: PopoverController) { 
    
  }

  ngOnInit() {}

  optionSelected(option:string){
    this.popCtrl.dismiss({opcion:option})
  }

}
