import {
  Component,
  Input,
  Output,
  EventEmitter,
  HostListener,
  OnInit,
} from "@angular/core";
import { Router } from "@angular/router";

@Component({
  selector: "app-video-modal",
  templateUrl: "./video-modal.component.html",
  styleUrls: ["./video-modal.component.scss"],
})
export class VideoModalComponent implements OnInit {
  @HostListener("window:resize", ["$event"])
  onResize(event) {
    this.setScreenSize();
  }

  @Output() closeEventEmit: EventEmitter<void> = new EventEmitter<void>();
  @Input() showPopUp: boolean = true;
  @Input() sourceMedia: string = "";
  @Input() urlRedirect: string = "";
  @Input() button!: boolean;
  @Input() routerlink!: string;
  @Input() urlType!: number;  // Nuevo input para tipo de link

  public isSmallScreen: boolean = false;
  public isVideo: boolean = false;
  public isPhoto: boolean = false;
  public showSpinner: boolean = true;
  
@HostListener('document:click', ['$event'])
onClick(event: MouseEvent) {
    const modalElement = document.getElementById('modal-image'); 
    this.closeEventEmit.emit();
}
  constructor(private router: Router) {
    
  }

  ngOnInit() {

    this.urlType = this.urlType && this.urlType[0].Status


    try {
      if (this.sourceMedia.endsWith(".mp4")) this.isVideo = true;
      else if (
        this.sourceMedia.endsWith(".png") ||
        this.sourceMedia.endsWith(".jpg") ||
        this.sourceMedia.endsWith(".web") ||
        this.sourceMedia.endsWith(".gif")
      )
        this.isPhoto = true;
    } catch (error) {
      console.error(error);
    }
  }

  /**
   * function to define the size of images according to the size of the device
   */
  public setScreenSize = (): void => {
    this.isSmallScreen = window.screen.availWidth <= 540;
  };

  /**
   * To emit the button event was pressed
   */
  public closeEmit = (): void => {
    this.closeEventEmit.emit();
  };

  public exitOnClick(event: CustomEvent<HTMLElement>) {
    let target = event.target as HTMLElement;
    if (
      target.id !== "video-player" &&
      target.id !== "image-popup" &&
      target.id !== "spinner"
    )
      this.closeEmit();
  }

  initPopUp() {
    try {
      if (this.sourceMedia.endsWith('.mp4')) this.isVideo = true;
      else if (this.sourceMedia.endsWith('.png') || this.sourceMedia.endsWith('.jpg') || this.sourceMedia.endsWith('.web') || this.sourceMedia.endsWith('.gif')) this.isPhoto = true;
    } catch (error) {
      console.error(error)
    }
  }

  handleClick() {

    if (this.urlType == 1) {
      // Enlace externo
      window.open(this.urlRedirect, '_blank');

      this.showPopUp= false;
    } else if (this.urlType == 0) {
      // Enlace interno
      this.router.navigate([this.urlRedirect]);
    }
    else this.showPopUp = false;
  }
}
