import * as tslib_1 from "tslib";
import { OnInit, EventEmitter, OnDestroy, OnChanges, SimpleChanges } from '@angular/core';
import { AlertController, IonSelect, ToastController } from '@ionic/angular';
import { WebsocketAPI } from '../../../../app/lib/websocket.api';
import { environment } from '../../../../environments/environment';
import { HelperService } from '../../../services/helper.service';
import { LocalStorageService } from '../../../services/local-storage.service';
import { ClubFibexFeedingService } from '../../../services/club-fibex-feeding.service';
export class ModalShowQrComponent {
    constructor(helper, localStorage, alertCtrl, toastController, clubFibex) {
        this.helper = helper;
        this.localStorage = localStorage;
        this.alertCtrl = alertCtrl;
        this.toastController = toastController;
        this.clubFibex = clubFibex;
        this.cuponname = null;
        this.imgproduct = null;
        this.qrcode = null;
        this.linkRedirect = null;
        this.LinkPromo = null;
        this.qrimage = null;
        this.couponCount = 0;
        this.backCounter = 0;
        this.idEmpConv = "";
        this.isQRListView = false;
        this.alreadyGenerated = false;
        this.generating = false;
        this.generateQR = new EventEmitter();
        this.closeButton = new EventEmitter();
        this.acceptButton = new EventEmitter();
        this.acceptCuponSuccessed = new EventEmitter();
        this.setCurrentBranch = new EventEmitter();
        this.redirection = new EventEmitter();
        this.downloadBill = new EventEmitter();
        this.socket = null;
        this.succesedCupon = false;
        this.messageCouponDays = "";
        this.userData = this.localStorage.get('CedulaActual');
        this.downloading = false;
        this.isLoadedDaysExpiredCoupon = false;
        this.isLoadedNumberPhoneEnterprise = false;
    }
    ngOnInit() {
        this.FinishWebsocketNotificationQR();
        this.getDaysExpiredCoupon();
    }
    ngOnDestroy() {
        this.succesedCupon = false;
        this.FinishWebsocketNotificationQR();
    }
    ngOnChanges(changes) {
        const qrcode = changes["qrcode"];
        if (qrcode && qrcode.previousValue !== qrcode.currentValue) {
            this.getDaysExpiredCoupon();
            this.FinishWebsocketNotificationQR();
            // this.getDataContactEnterprise();
            if (qrcode.currentValue) {
                this.InitWebsocketNotificationQR();
            }
        }
    }
    openBranchOfficeSelector() {
        this.branchOfficeSelector.open();
    }
    /**
     * Inicializar servicio para notificar QR
     */
    InitWebsocketNotificationQR() {
        // Iniciar
        if (this.qrcode && !this.socket && !this.succesedCupon) {
            // Crear conexión hacia al servidor
            this.socket = new WebsocketAPI(environment.apiTestWS + "/qr-notify/" + this.qrcode);
            this.socket.onclose = () => {
                // eliminar la variable al cerrar la conexión para permitir la entrada de esta funcion nuevamente
                delete this.socket;
                if (this.qrcode) {
                    // Reintentar a los 3 segundos
                    setTimeout(() => {
                        this.InitWebsocketNotificationQR();
                    }, 3000);
                }
            };
            // Manejar eventos (actualmente 1)
            this.socket.onmessage = (event, data) => {
                switch (event) {
                    case "notify_redeemed_coupon":
                        // Cupon canejado correctamente (ES NECESARIO ACTUALIZAR)
                        // ...
                        // alert("CUPON ESCANEADO CON EXITO");
                        this.succesedCupon = true;
                        this.socket.close(); // !!!! IMPORTANTE CERRAR LA CONEXIÓN  AL RECIBIR ESTE EVENTO, PARA QUE NO QUEDE EN EL AIRE
                        break;
                }
            };
        }
    }
    FinishWebsocketNotificationQR() {
        if (this.socket) {
            try {
                this.socket.close();
            }
            catch (err) {
                console.error(err);
            }
            delete this.socket;
        }
    }
    downloadQR() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const { cedula } = this.userData;
            // this.helper.sendBillsByWhatsApp()
            const alertCtrl = yield this.alertCtrl.create({
                header: 'Cupón enviado',
                message: 'En breve recibirá en su dispositivo móvil el cupón seleccionado.',
                cssClass: 'secondary cust',
                buttons: [
                    {
                        text: 'Cerrar',
                        role: 'Cancelar',
                        cssClass: 'cust',
                    }
                ]
            });
            alertCtrl.present();
        });
    }
    emitRedirection() {
        this.redirection.emit();
    }
    emitDownloadBill() {
        this.downloading = true;
        this.downloadBill.emit();
    }
    emitStatus() {
    }
    copyQR(code) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const toast = yield this.toastController.create({
                message: 'Se ha sido copiado en el portapapeles correctamente',
                duration: 1500,
                position: "bottom",
            });
            yield toast.present();
            if (navigator.clipboard && code) {
                try {
                    yield navigator.clipboard.writeText(code);
                }
                catch (err) {
                    console.error('Error al copiar texto: ', err);
                }
            }
        });
    }
    getDaysExpiredCoupon() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (this.qrcode) {
                const responseExpiredCoupon = yield this.clubFibex.getDaysCoupon(this.qrcode);
                if (responseExpiredCoupon && responseExpiredCoupon.status) {
                    this.isLoadedDaysExpiredCoupon = true;
                    //Calculando los dias que han pasado
                    const dateCouponCreate = new Date(responseExpiredCoupon.FechaGenerado);
                    const currentDate = new Date();
                    //resto la fecha generado con la actual
                    let diff = Math.abs(currentDate.getTime() - dateCouponCreate.getTime());
                    let daysElapsed = Math.round(diff / (1000 * 60 * 60 * 24));
                    const daysLimit = responseExpiredCoupon.FechaLimite;
                    //Obtengo la diferencia de cuantos dias faltan
                    const daysDifference = daysLimit - daysElapsed;
                    let days = daysDifference < 2 ? "día" : "días";
                    let messages = `Recuerda que tienes ${daysDifference} ${days} para canjear tu cupón y disfrutar de esta promoción.`;
                    if (daysDifference < 1)
                        messages = "Su cupón ha vencido, por lo tanto no podrá disfrutar de esta promoción. ";
                    this.messageCouponDays = messages;
                }
            }
        });
    }
    getDataContactEnterprise() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.dataContactEnterprise = yield this.clubFibex.getContactDateByEnterprise(this.idEmpConv);
            this.isLoadedNumberPhoneEnterprise = true;
        });
    }
}
