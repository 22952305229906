import * as tslib_1 from "tslib";
import { LettersService } from '../../../../services/letters.service';
import { ClubFibexFeedingService } from '../../../../services/club-fibex-feeding.service';
import { OnInit, EventEmitter, ElementRef, OnChanges, SimpleChanges } from '@angular/core';
export class ModalLocationComponent {
    constructor(letterService, clubService) {
        this.letterService = letterService;
        this.clubService = clubService;
        this.styleClass = '';
        this.isModalMode = true;
        this.selectedCities = [];
        this.close = new EventEmitter();
        this.franqSelectedEvent = new EventEmitter();
        this.franquicias = [];
        this.filteredItems = [];
        this.FranqSelectedId = [];
        this.loadingData = false;
        this.areLoadingElementes = false;
        this.allElementsLoades = false;
        this.limit = 15;
        this.offset = 1;
        this.toString = (object) => {
            return JSON.stringify(object);
        };
    }
    ngOnInit() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            yield this.initData();
        });
    }
    ngOnChanges(changes) {
        if (changes['selectedCities'].currentValue.length > 0) {
            if (this.FranqSelectedId.length < 1)
                this.FranqSelectedId = [...this.selectedCities];
        }
        else if (changes['selectedCities'].currentValue.length < 1) {
            this.FranqSelectedId = [];
        }
    }
    initData() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.loadingData = true;
            if (this.selectedCities.length > 0) {
                for (const franqId of this.selectedCities) {
                    this.FranqSelectedId.push(franqId);
                }
            }
            this.franquicias = yield this.clubService.getSucursales();
            this.franquicias.map(franq => franq.Nombre = franq.Nombre.replace('FIBEX ', ''));
            this.filteredItems = [...this.franquicias];
            this.loadingData = false;
        });
    }
    trackItems(index, item) {
        return item.idEnSAE;
    }
    cancel() {
        this.close.emit();
    }
    confirmChanges() {
        this.franqSelectedEvent.emit(this.FranqSelectedId);
        /*
         !En caso que se requiera guardar las franquicias en el localStorage
         this.localStorageService.set('user_franq', this.FranqSelectedId || [])
        */
    }
    searchbarInput(ev) {
        this.filterList(ev.target.value);
    }
    moveBackScroll() {
        this.scrollableDiv.nativeElement.scrollLeft -= 20;
    }
    moveForwardScroll() {
        this.scrollableDiv.nativeElement.scrollLeft += 20;
    }
    /**
     * Update the rendered view with
     * the provided search query. If no
     * query is provided, all data
     * will be rendered.
     */
    filterList(searchQuery) {
        /**
         * If no search query is defined,
         * return all options.
         */
        if (searchQuery === undefined) {
            this.filteredItems = [...this.franquicias];
        }
        else {
            /**
             * Otherwise, normalize the search
             * query and check to see which items
             * contain the search query as a substring.
             */
            const normalizedQuery = searchQuery.toLowerCase();
            this.filteredItems = this.franquicias.filter((item) => {
                return item.Nombre.toLowerCase().includes(normalizedQuery);
            });
        }
    }
    isChecked(value) {
        return this.FranqSelectedId.find((item) => item === value);
    }
    checkboxChange(ev) {
        const checked = ev.target.checked;
        const value = ev.target.value;
        if (checked) {
            this.FranqSelectedId = [...this.FranqSelectedId, value];
        }
        else {
            this.FranqSelectedId = this.FranqSelectedId.filter((franquicia) => franquicia !== value);
        }
        if (!this.isModalMode)
            this.confirmChanges();
    }
}
