import * as tslib_1 from "tslib";
import { ChangeDetectorRef, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { ClubFibexFeedingService } from '../../../services/club-fibex-feeding.service';
var COUPON_MONEY_TYPE;
(function (COUPON_MONEY_TYPE) {
    COUPON_MONEY_TYPE["DISCOUNT"] = "discount";
    COUPON_MONEY_TYPE["DOLLAR"] = "dollar";
})(COUPON_MONEY_TYPE || (COUPON_MONEY_TYPE = {}));
export class FilterComponent {
    constructor(router, modalController, clubFibexService, route, cdr) {
        this.router = router;
        this.modalController = modalController;
        this.clubFibexService = clubFibexService;
        this.route = route;
        this.cdr = cdr;
        this.groupCategory = [];
        this.activeCat = [];
        this.rate = 'all';
        this.price = 'all';
        this.rangePrices = ['1$', '10$', '50$', '100$', '+100$'];
        this.stars = [];
        this.categories = [];
        this.StarsSelected = [];
        this.minRange = '0';
        this.maxRange = '1000';
        this.COUPON_MONEY_TYPES = COUPON_MONEY_TYPE;
        this.activeTabMoneyType = COUPON_MONEY_TYPE.DISCOUNT;
        this.companies = [];
        this.showResetFilter = false;
        this.showFranqModal = false;
        this.showCompanyModal = false;
        this.allFranqIndexed = []; //Franq indexed by its idFranquicia, for optimization
        this.allCompaniesIndexed = [];
        this.selectedCities = [];
        this.selectedCompanies = [];
        this.activeOptionTab = false;
        this.urlIsHome = false;
        this.validateAllSelectedItems = () => {
            try {
                const currentUrl = this.router.url;
                //ESTA VALIDACION PERMITIRA OCULTYAR LAS SECCIONES QUE NO SE RELACIONAN CON LA SECCION DE LAS MARCAS ALIADAS
                if (currentUrl.includes("/all-marks"))
                    this.activeOptionTab = true;
                this.paramSub = this.route.queryParamMap.subscribe((data) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                    const { params } = data;
                    if (params.qCategory) {
                        const catIdSelected = params.qCategory.split("--");
                        if (catIdSelected.length) {
                            this.activeCat = this.categories.filter(cat => catIdSelected.includes(cat.idEmpCat));
                            this.showResetFilter = true;
                        }
                    }
                    if (params.qPricing) {
                        const range = params.qPricing.split('--');
                        this.minRange = range[0];
                        this.maxRange = range[1];
                        if (this.minRange !== '0' || this.maxRange !== '1000')
                            this.showResetFilter = true;
                    }
                    if (params.qDiscountType) {
                        this.activeTabMoneyType = params.qDiscountType;
                        if (this.activeTabMoneyType === COUPON_MONEY_TYPE.DOLLAR)
                            this.showResetFilter = true;
                    }
                    //activeFranquicies
                    if (params.qCities) {
                        const franquicies = params.qCities.split("--");
                        if (franquicies.length)
                            this.showResetFilter = true;
                        this.selectedCities = franquicies;
                    }
                    if (params.qCompanies) {
                        const companies = params.qCompanies.split("--");
                        if (companies.length)
                            this.showResetFilter = true;
                        this.selectedCompanies = companies;
                    }
                    if (params.qfilterCitiesByComp) {
                        this.activeOptionTab = params.qfilterCitiesByComp === 'true';
                    }
                }));
            }
            catch (error) {
                console.error(error);
            }
        };
        this.toNumber = (text) => {
            return Number(text);
        };
        /**
         * To transform the money type to all products
         */
        this.transformCoupMoney = (moneyType) => {
            try {
                this.activeTabMoneyType = moneyType;
            }
            catch (error) {
                console.error(error);
            }
        };
        this.resetFilter = () => {
            try {
                this.activeTabMoneyType = COUPON_MONEY_TYPE.DISCOUNT;
                this.activeCat = [];
                this.minRange = "0";
                this.maxRange = "1000";
                this.showResetFilter = false;
                this.selectedCities = [];
                this.selectedCompanies = [];
            }
            catch (error) {
                console.error(error);
            }
        };
        this.handleShowFranqModal = (value) => {
            // if (value) this.selectedCities = [];
            this.showFranqModal = value;
            this.cdr.detectChanges();
        };
        this.handleShowCompanyModal = (value) => {
            // if (value) this.selectedCities = [];
            this.showCompanyModal = value;
            this.cdr.detectChanges();
        };
        this.onFranqSelected = (franquicias) => {
            try {
                this.showFranqModal = false;
                this.selectedCities = franquicias;
                this.handleShowFranqModal(false);
            }
            catch (error) {
                console.error(error);
            }
        };
        this.onCompaniesSelected = (companies) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let allCompanies = [];
                this.showCompanyModal = false;
                this.selectedCompanies = companies;
                if (this.selectedCompanies.length > 0)
                    allCompanies = yield this.clubFibexService.SearchCompaniesById(companies.map(value => `'${value}'`).join(","));
                for (let item of allCompanies) {
                    if (!this.allCompaniesIndexed[item.id])
                        this.allCompaniesIndexed[item.id] = item;
                }
                this.handleShowCompanyModal(false);
            }
            catch (error) {
                console.error(error);
            }
        });
    }
    ngOnInit() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                this.cdr.detectChanges();
                this.categories = yield this.clubFibexService.GetCategories();
                this.validateAllSelectedItems();
                let allFranq = yield this.clubFibexService.getSucursales();
                let allCompanies = [];
                const urlTree = this.router.parseUrl(this.router.url);
                const urlWithoutQueryParams = urlTree.root.children['primary'].segments.map(it => it.path).join('/');
                this.currentURL = urlWithoutQueryParams;
                this.urlIsHome = this.currentURL.includes('home');
                if (this.selectedCompanies.length > 0)
                    allCompanies = yield this.clubFibexService.SearchCompaniesById(this.selectedCompanies.map(value => `'${value}'`).join(","));
                allFranq.map(franq => franq.Nombre = franq.Nombre.replace('FIBEX ', ''));
            }
            catch (error) {
                console.error(error);
            }
        });
    }
    ngOnDestroy() {
        if (this.paramSub)
            this.paramSub.unsubscribe();
    }
    addActiveCat(category) {
        const index = this.activeCat.findIndex(cat => cat.idEmpCat === category.idEmpCat);
        if (index === -1) {
            this.activeCat.push(category);
        }
        else {
            this.activeCat = this.activeCat.filter(cat => cat.idEmpCat !== category.idEmpCat);
        }
    }
    addActiveStar(star) {
        let index = this.StarsSelected.findIndex(sta => sta.id === star.id);
        if (index === -1) {
            this.StarsSelected.push(star);
        }
        else {
            this.StarsSelected = this.StarsSelected.filter(element => element.id !== star.id);
        }
    }
    initFn() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const categories = yield this.clubFibexService.GetCategories();
            this.groupCategory = categories.map((category) => category.nombre);
        });
    }
    onBackNavigation() {
        this.modalController.dismiss();
    }
    onNavigation() {
        const arrArg = [];
        this.activeCat.forEach(element => {
            if (element.idEmpCat)
                arrArg.push(element.idEmpCat);
        });
        const qParam = arrArg.join('--');
        this.modalController.dismiss();
        this.router.navigate(this.urlIsHome ? [this.currentURL]
            : [`/club-fibex/${this.activeOptionTab ? 'all-marks' : 'product-list'}`], {
            queryParams: {
                qCategory: arrArg.length ? qParam : null,
                qPricing: this.minRange ? `${this.minRange}--${this.maxRange}` : null,
                qDiscountType: this.activeTabMoneyType,
                qCities: this.selectedCities.join('--'),
                qCompanies: this.selectedCompanies.join('--'),
                qfilterCitiesByComp: this.activeOptionTab,
                qTypeSearch: this.activeOptionTab ? 1 : 0
            },
            replaceUrl: true,
            queryParamsHandling: 'merge'
        });
        // Promise.resolve().then(() => {
        //   window.location.reload()
        // })
    }
    setStarSelected(item) {
        this.StarsSelected = item.id;
    }
    trigger(ev) {
        const { detail } = ev;
        const { value } = detail;
        this.minRange = `${value.lower}`;
        this.maxRange = `${value.upper}`;
    }
    hasSelectedCat(category) {
        return (this.activeCat.includes(category));
    }
    hasSelectedStar(star) {
        return (this.StarsSelected.includes(star));
    }
    onChangeMinRAnge($event) {
        this.minRange = $event.target.value;
    }
    onChangemaxRange($event) {
        this.maxRange = $event.target.value;
    }
    itemAccordion(element, id) {
        const elementId = element.getAttribute('data-faq');
        if (id === parseInt(elementId))
            element.classList.toggle('activa');
        const anwser = element.children[1];
        const ionIcon = element.querySelector('ion-icon');
        ionIcon.classList.toggle('rotate-icon');
        const height = anwser.scrollHeight;
        !anwser.style.maxHeight
            ? anwser.style.maxHeight = height + "px"
            : anwser.style.maxHeight = null;
    }
    setOPtionTab(value) {
        this.activeOptionTab = value;
    }
}
