import { Component, Input, OnInit, ViewChild, ElementRef, OnChanges } from '@angular/core';
import { Icon, Map, tileLayer, Marker } from 'leaflet';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
})
export class MapComponent implements OnInit, OnChanges {
  @ViewChild("map_container", { static: true }) map_container!: ElementRef;
  
  @Input() latitude: number;
  @Input() longitude: number;

  /** 
   * instancia de leaflet 
   * */
  public map: Map | null = null;
  /**
   * marcador del mapa
   */
  public marker!: Marker;


  ngOnInit() {
    this.InicializeMap();
  }

  ngOnChanges() {
    if(this.map && this.marker) {
      let location = this.marker.getLatLng();

      if(location.lat !== this.latitude || location.lng !== this.longitude) {
        // Actualizar hacia la nuevas coordenadas en caso de cambios
        const newLocation: [number, number] = [this.latitude, this.longitude];
        this.marker.setLatLng(newLocation);
        this.map.flyTo(newLocation);
      }
    }
  }

  InicializeMap() {
    if(!this.map) {
      const location: [number, number] = [this.latitude, this.longitude];  
      // Crear mapa
      this.map = new Map(this.map_container.nativeElement);
      this.map.setView(location, 18);
  
      // Crear marcador del mapa
      this.marker = new Marker(location, { draggable: false });
      this.marker.addTo(this.map);
      this.marker.bindPopup(`Ubicación seleccionada`);
  
      this.map.flyTo(location);
  
      // nose que es pero está aqui :]... me parece como que es su configuración inicial
      tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
        maxZoom: 100,
        attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      }).addTo(this.map);
    }
  }
}
