import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { fromEvent } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class NetworkStatusService {

  public status: boolean = true;

  constructor(public toastController: ToastController) {

    fromEvent(window, 'online').pipe(
      debounceTime(100)).subscribe(async (event: Event) => {
        const toast = await this.toastController.create({
          message: 'Estas conectado a internet.',
          showCloseButton: true,
          closeButtonText: 'Cerrar',
          duration: 3000,
          color: 'success'
        });
        toast.present();

        this.status = true;
      });

    fromEvent(window, 'offline').pipe(
      debounceTime(100)).subscribe(async (event: Event) => {
        const toast = await this.toastController.create({
          message: 'No estas conectado a internet.',
          showCloseButton: true,
          closeButtonText: 'Cerrar',
          duration: 3000,
          color: 'danger'
        });
        toast.present();

        this.status = false;
      });
  }

  getStatus() {
    return this.status;
  }
}