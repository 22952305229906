import { Component, OnInit, Input, Output, EventEmitter, ViewChild, OnDestroy, OnChanges, SimpleChanges } from '@angular/core';
import { AlertController, IonSelect, ToastController } from '@ionic/angular';
import { WebsocketAPI } from '../../../../app/lib/websocket.api';
import { environment } from '../../../../environments/environment';
import { HelperService } from '../../../services/helper.service';
import { LocalStorageService } from '../../../services/local-storage.service';
import { ClubFibexFeedingService } from '../../../services/club-fibex-feeding.service';
import { IUserSae, UserAuthenticationService } from '../../../services/user-authentication.service';



@Component({
  selector: 'app-modal-show-qr',
  templateUrl: './modal-show-qr.component.html',
  styleUrls: ['./modal-show-qr.component.scss']
})
export class ModalShowQrComponent implements OnInit, OnDestroy, OnChanges {
  @ViewChild('branchOfficeSelector', { static: false }) branchOfficeSelector: IonSelect;
  
  @Input() cuponname: string | null = null;
  @Input() imgproduct: string | null = null;
  @Input() qrcode: string | null = null;
  @Input() linkRedirect: string | null = null;
  @Input() LinkPromo: string | null = null;
  @Input() qrimage: string | null = null;
  @Input() companyName?: string;
  @Input() companyLogo?: string;
  @Input() couponCount: number = 0;
  @Input() backCounter: number = 0;
  @Input() idEmpConv: string = "";
  //!👇👇 please work with its types
  @Input() branchOffices: any; // TODO NO ESTA BIEN EL ANY
  @Input() isQRListView: boolean = false;
  @Input() alreadyGenerated: boolean = false;
  @Input() generating: boolean = false;

  @Output() generateQR: EventEmitter<void> = new EventEmitter();
  @Output() closeButton: EventEmitter<void> = new EventEmitter();
  @Output() acceptButton: EventEmitter<void> = new EventEmitter();
  @Output() acceptCuponSuccessed: EventEmitter<void> = new EventEmitter();
  @Output() setCurrentBranch: EventEmitter<any> = new EventEmitter();
  @Output() redirection: EventEmitter<any> = new EventEmitter();
  @Output() downloadBill: EventEmitter<any> = new EventEmitter();


  private socket: WebsocketAPI | null = null;
  public succesedCupon: boolean = false;
  public messageCouponDays: string = "";
  userData = this.localStorage.get('CedulaActual')
  downloading: boolean = false;
  public isLoadedDaysExpiredCoupon: boolean = false;
  public isLoadedNumberPhoneEnterprise: boolean = false;
  public dataContactEnterprise: any;
  constructor(private helper: HelperService, private localStorage: LocalStorageService, private alertCtrl: AlertController, private toastController: ToastController, private clubFibex: ClubFibexFeedingService ) { }

  ngOnInit() {
    this.FinishWebsocketNotificationQR();
this.getDaysExpiredCoupon(); 
  }

  ngOnDestroy() {
    this.succesedCupon = false;
    this.FinishWebsocketNotificationQR();
  }

  ngOnChanges(changes: SimpleChanges): void {
    const qrcode = changes["qrcode"];
    if(qrcode && qrcode.previousValue !== qrcode.currentValue) {
      this.getDaysExpiredCoupon();
      this.FinishWebsocketNotificationQR();
      // this.getDataContactEnterprise();
      if(qrcode.currentValue) {
        this.InitWebsocketNotificationQR();
      }
    }
  }
  
  openBranchOfficeSelector(){
    this.branchOfficeSelector.open();
  }


  /**
   * Inicializar servicio para notificar QR
   */
  InitWebsocketNotificationQR() {
    // Iniciar
    if(this.qrcode && !this.socket && !this.succesedCupon) {
      // Crear conexión hacia al servidor
      this.socket = new WebsocketAPI(environment.apiTestWS + "/qr-notify/" + this.qrcode);
      this.socket.onclose = () => {
        // eliminar la variable al cerrar la conexión para permitir la entrada de esta funcion nuevamente
        delete this.socket;
        
        if(this.qrcode) {
          // Reintentar a los 3 segundos
          setTimeout(() => {
            this.InitWebsocketNotificationQR();
          }, 3000)
        }
      }

      // Manejar eventos (actualmente 1)
      this.socket.onmessage = (event: "notify_redeemed_coupon", data: { qr: string }) => {
        switch(event) {
          case "notify_redeemed_coupon":
            // Cupon canejado correctamente (ES NECESARIO ACTUALIZAR)
            // ...
            // alert("CUPON ESCANEADO CON EXITO");
            this.succesedCupon = true;
            
            this.socket.close(); // !!!! IMPORTANTE CERRAR LA CONEXIÓN  AL RECIBIR ESTE EVENTO, PARA QUE NO QUEDE EN EL AIRE
            break;
        }
      }
    }
  }

  FinishWebsocketNotificationQR() {
    if(this.socket) {
      try {
        this.socket.close();
      }
      catch(err) {
        console.error(err);
      }
      delete this.socket;
    }
  }

  async downloadQR(){
    const { cedula } = this.userData
    // this.helper.sendBillsByWhatsApp()
    
    const alertCtrl = await this.alertCtrl.create({
      header: 'Cupón enviado',
      message: 'En breve recibirá en su dispositivo móvil el cupón seleccionado.',
      cssClass: 'secondary cust',
      buttons: [
        {
          text: 'Cerrar',
          role: 'Cancelar',
          cssClass: 'cust',
        }
      ]
    })

    alertCtrl.present()
  }

  emitRedirection(){
    this.redirection.emit();
  }

  emitDownloadBill(){
    this.downloading = true;
    this.downloadBill.emit();
  }

  emitStatus(){
  }
  async copyQR(code: any){
    const toast = await this.toastController.create({
      message: 'Se ha sido copiado en el portapapeles correctamente',
      duration: 1500,
      position: "bottom",
    });
    await toast.present();
    if (navigator.clipboard && code) {
      try {
        await navigator.clipboard.writeText(code);
       
      } catch (err) {
        console.error('Error al copiar texto: ', err);
      }
  }
  }

  async getDaysExpiredCoupon(){
    if(this.qrcode){
      const responseExpiredCoupon: any = await this.clubFibex.getDaysCoupon(this.qrcode);
      if(responseExpiredCoupon  && responseExpiredCoupon.status){
        this.isLoadedDaysExpiredCoupon = true;
        //Calculando los dias que han pasado
        const dateCouponCreate = new Date(responseExpiredCoupon.FechaGenerado);
        const currentDate = new Date();
        //resto la fecha generado con la actual
        let diff = Math.abs(currentDate.getTime()-dateCouponCreate.getTime());
        let daysElapsed = Math.round(diff/(1000*60*60*24));
        
        const daysLimit: number = responseExpiredCoupon.FechaLimite;
        //Obtengo la diferencia de cuantos dias faltan
        const daysDifference = daysLimit-daysElapsed;
        let days: string = daysDifference<2 ? "día" : "días";
        let messages = `Recuerda que tienes ${daysDifference} ${days} para canjear tu cupón y disfrutar de esta promoción.`;
        if(daysDifference<1) messages = "Su cupón ha vencido, por lo tanto no podrá disfrutar de esta promoción. "; 
        this.messageCouponDays = messages;
      }
    }
  
  } 
  async getDataContactEnterprise(){
    this.dataContactEnterprise = await this.clubFibex.getContactDateByEnterprise(this.idEmpConv);
    
    this.isLoadedNumberPhoneEnterprise = true;
  }
}
