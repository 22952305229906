import * as i0 from "@angular/core";
export class ClearCacheService {
    constructor() { }
    clear() {
        if (window.caches) { // ! verificamos si existe cache
            caches.keys().then(keys => {
                if (keys.length > 0)
                    keys.forEach(key => caches.delete(key));
            });
        }
        if ('serviceWorker' in navigator) { // Elimina cache definitivamente
            navigator.serviceWorker.getRegistrations().then((registrations) => {
                registrations.forEach(registration => {
                    registration.unregister();
                });
            });
        }
    }
}
ClearCacheService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ClearCacheService_Factory() { return new ClearCacheService(); }, token: ClearCacheService, providedIn: "root" });
