import * as tslib_1 from "tslib";
import { EventEmitter } from '@angular/core';
import { UserInfo } from '../interfaces/userInfo';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { LocalStorageService } from './local-storage.service';
import { LogErrorsService } from './log-errors.service';
import { ConsultasService } from './consultas.service';
import { SeguridadDatos } from './bscript.service';
import { ExtractUrl } from '../lib/extract.url.params';
import { BehaviorSubject } from 'rxjs';
import { CedulaStorage } from './cedula-storage.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./local-storage.service";
import * as i3 from "./log-errors.service";
import * as i4 from "./consultas.service";
import * as i5 from "./bscript.service";
import * as i6 from "./cedula-storage.service";
export class UserAuthenticationService {
    constructor(http, _localStorageService, _logs, _consulta, security, cedulaStorage) {
        this.http = http;
        this._localStorageService = _localStorageService;
        this._logs = _logs;
        this._consulta = _consulta;
        this.security = security;
        this.cedulaStorage = cedulaStorage;
        this.userState = new EventEmitter();
        /**
         * Variables en el servicio
        */
        this.isLogged = true;
        this.app$ = new BehaviorSubject(false);
        this.isAuthenticated$ = new BehaviorSubject(false);
        const params = ExtractUrl.GetParamsThisPage();
        if (params['dni'] && params['usermail'] && params['userphone']) {
            this.tokenExtern = params;
        }
        else {
            this.app$.next(false);
        }
    }
    /**
    * Getters y Setters de las variables
    */
    getIsLogged() { return this.isLogged; }
    ;
    setIsLogged(value) { this.isLogged = value; }
    ;
    getUser() {
        const userData = this._localStorageService.get('DataToRegister');
        return (userData && userData.DataUserSae) || null;
    }
    getUsers() {
        const userData = this._localStorageService.get('DataToRegister');
        return userData ? userData.DataUser : [];
    }
    selectUser(nro_contrato) {
        const users = this.getUsers();
        const user = users.find(userItem => userItem.nro_contrato === nro_contrato);
        if (user) {
            const userData = this._localStorageService.get("DataToRegister");
            this._localStorageService.set("DataToRegister", Object.assign({}, userData, { DataUserSae: user }));
            return user;
        }
    }
    registerUser(userInfo) {
        this.login({ email: userInfo.email, password: userInfo.password, telefono: userInfo.phone });
        return true;
    }
    noRegisterdUser() {
        const users = this.getUser();
        if (!users) {
            return true;
        }
        return false;
    }
    login(userInfo) {
        const registeredUser = this.getUser();
        if (!registeredUser) {
            return false;
        }
        // const validUser = (userInfo.email.toUpperCase() === registeredUser.email.toUpperCase() && userInfo.password.replace(/[^\d]/gi, '') === registeredUser.password.replace(/[^\d]/gi, ''));
        const validUser = (userInfo.email.toUpperCase() === registeredUser.email.toUpperCase());
        const validPhoneUser = (userInfo.telefono === registeredUser.telefono);
        if (!validUser && !validPhoneUser) {
            return false;
        }
        this._localStorageService.set('auth-user', userInfo);
        return true;
    }
    modifyUser(userInfo) {
        const info = this._localStorageService.get("DataToRegister");
        let userInfoUpdate = info.DataUserSae = Object.assign({}, info.DataUserSae, userInfo);
        info.DataUser = info.DataUser.map((user) => {
            return user.id_contrato === userInfoUpdate.id_contrato ? userInfoUpdate : user;
        });
        this._localStorageService.set("DataToRegister", info);
        this.userState.emit(userInfoUpdate);
    }
    logout() {
        this._localStorageService.remove('auth-user');
    }
    loginCRM(email, password) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            //http://localhost/phpass.php?email=HADADJORGE1@GMAIL.COM&pass=jermsof
            try {
                const responseCliente = yield this.http.get(`${environment.apiPhP}email=${email}&pass=${password}`).toPromise();
                if (!responseCliente) {
                    return { status: false };
                }
                this._localStorageService.set('HaveFibexP', responseCliente.fibex_play);
                return responseCliente;
            }
            catch (error) {
                this._logs.insertarError(error.message);
                return { status: false };
            }
        });
    }
    resetPassword(email, password) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const response = yield this.http.get(`${environment.apiResetPassword}email=${email}&pass=${password}`).toPromise();
                return response;
            }
            catch (error) {
                this._logs.insertarError(error.message);
                return {
                    status: false,
                    email: null,
                    password: null,
                };
            }
        });
    }
    verifyCedula(cedula) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const response = yield this.http.get(`${environment.apiVerifyCedula}cedula=${cedula}`).toPromise();
                return response;
            }
            catch (error) {
                this._logs.insertarError(error.message);
                return {
                    status: false,
                    cedula: null,
                };
            }
        });
    }
    isAuthenticated() {
        const token = this.externalData();
        if (token) {
            const dataResponse = token;
            this.loginAuth(dataResponse).then((user) => {
                this.isAuthenticated$.next(true);
                //Guardar un valor en el local storage referente al inicio de sesion de manera externa
                this._localStorageService.set('is-auth-external', 'true');
                this.app$.next(true);
            }).catch((error) => {
                this.isAuthenticated$.next(false);
            });
        }
        else {
            this.isAuthenticated$.next(false);
        }
    }
    loginAuth({ dni, usermail, userphone, ask, answer }) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const responseConsulta = yield this._consulta.authUserCredentials({ dni, usermail, userphone, ask, answer });
                const data = responseConsulta.data.map((item) => (Object.assign({}, item, { cliente: (item.nombre + " " + item.apellido).trim() })));
                console.table('RES >>>', responseConsulta);
                //GENERAMOS TODOS LOS STORAGE DENTRO DEL LOCAL STORAGE
                this._localStorageService.set("DataToRegister", {
                    DataUser: data[0],
                    DataUserSae: data[0],
                    Email: data[0].email
                });
                this._localStorageService.set("auth", responseConsulta.token);
                const userInfo = {
                    email: data[0].email.toLowerCase(),
                    identidad: data.cedula || data[0].cedula,
                    tipoCliente: `${data.tipo_abonado} ${data.tipo_cliente}` ||
                        "CLIENTE NATURAL",
                    fullName: data[0]
                        ? data[0].cliente
                        : `${data[0].nombre.toLowerCase()} ${data.apellido.toLowerCase()}`,
                    phone: data[0].telefono,
                    password: data[0].cedula || "",
                    id_franq: data[0].id_franq,
                    nro_contrato: data[0].nro_contrato,
                    status: data.status_contrato,
                };
                const userData = {
                    email: data[0].email.toLowerCase(),
                    password: data[0].cedula,
                    telefono: data[0].telefono
                };
                this.registerUser(userInfo);
                const value = this.login(userData);
                this.cedulaStorage.setCedula(userInfo, data);
                this.setIsLogged(true);
                return { data, status: 'logged successfully' };
            }
            catch (error) {
                console.error('Error en loginAuth', error);
                throw error;
            }
        });
    }
    externalData() {
        return this.tokenExtern;
    }
    ;
    //Consultar los datos del localStorage referentes a la sesion del usuario. Solo indica si esta logueado de manera externa o no.
    isLoggedExternally() {
        return this._localStorageService.get('is-auth-external');
    }
}
UserAuthenticationService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UserAuthenticationService_Factory() { return new UserAuthenticationService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.LocalStorageService), i0.ɵɵinject(i3.LogErrorsService), i0.ɵɵinject(i4.ConsultasService), i0.ɵɵinject(i5.SeguridadDatos), i0.ɵɵinject(i6.CedulaStorage)); }, token: UserAuthenticationService, providedIn: "root" });
