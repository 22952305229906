import { EventEmitter } from '@angular/core';
import { LocalStorageService } from './local-storage.service';
import * as i0 from "@angular/core";
import * as i1 from "./local-storage.service";
export class CedulaStorage {
    constructor(_localStorageService) {
        this._localStorageService = _localStorageService;
        this.stateCedulas = new EventEmitter();
    }
    setCedula(userInfo, consultaData) {
        const data = this.getCedulas();
        const repited = this._checkRepited(data, userInfo.identidad);
        if (repited) {
            return true;
        }
        const numContratos = this._getNContratos(consultaData);
        const cuenta = Object.assign({}, userInfo, { numContratos });
        const updated = [...data, cuenta];
        this._localStorageService.set('cedulas', updated);
        this.stateCedulas.emit(updated);
        return false;
    }
    getCedulas() {
        return this._localStorageService.get('cedulas') || [];
    }
    deleteCedula(identidad) {
        try {
            const info = this._localStorageService.get("DataToRegister");
            if (info) {
                this._localStorageService.set("DataToRegister", Object.assign({}, info, { DataUser: info.DataUser.filter((item) => {
                        return item.cedula !== identidad;
                    }) }));
            }
        }
        catch (err) {
        }
        const data = this.getCedulas();
        if (data.length <= 1) {
            return;
        }
        const updated = data.filter((item) => item.identidad !== identidad);
        this._localStorageService.set('cedulas', updated);
        this.stateCedulas.emit(updated);
    }
    _checkRepited(data, identidad) {
        return data.map((item) => item.identidad).includes(identidad);
    }
    editCedula(identidad, userInfo, consultaData) {
        const data = this.getCedulas();
        if (identidad !== userInfo.identidad && this._checkRepited(data, userInfo.identidad)) {
            return true;
        }
        const numContratos = this._getNContratos(consultaData);
        const cuenta = Object.assign({}, userInfo, { numContratos });
        const updated = data.map((item) => item.identidad === identidad ? cuenta : item);
        this._localStorageService.set('cedulas', updated);
        this.stateCedulas.emit(updated);
        return false;
    }
    _getNContratos(consultaData) {
        return consultaData.map((data) => data.nro_contrato);
    }
    getFisrt() {
        const cedulas = this.getCedulas();
        if (!cedulas.length) {
            return null;
        }
        const first = {
            id: 1,
            Cedula: `${cedulas[0].numContratos.length > 1 ? `${cedulas[0].identidad} (${cedulas[0].numContratos[0]})` : `${cedulas[0].identidad}`}`,
            identidad: cedulas[0].identidad,
            NContrato: cedulas[0].numContratos[0],
        };
        return first;
    }
}
CedulaStorage.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CedulaStorage_Factory() { return new CedulaStorage(i0.ɵɵinject(i1.LocalStorageService)); }, token: CedulaStorage, providedIn: "root" });
