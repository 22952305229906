import { NavController } from '@ionic/angular';
import { EstadisticasService } from './estadisticas.service';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "@ionic/angular";
import * as i2 from "./estadisticas.service";
import * as i3 from "@angular/router";
export class RoutingAppService {
    constructor(navCtrl, _estadistica, _router) {
        this.navCtrl = navCtrl;
        this._estadistica = _estadistica;
        this._router = _router;
        this.showSpinnerSubject = new BehaviorSubject(false);
        //* to set showSpinnerSubject true if the routing is loading | or false if routing is loaded
        this.subRouterEvents = () => {
            this.routerSub = this._router.events.subscribe((routerEvent) => {
                if (routerEvent instanceof NavigationStart) {
                    this.showSpinnerSubject.next(true);
                }
                if (routerEvent instanceof NavigationEnd) {
                    this.showSpinnerSubject.next(false);
                }
            });
        };
        //* get the value of showSpinnerSubject
        this.getShowSpinnerSubject = () => {
            return this.showSpinnerSubject.asObservable();
        };
    }
    ChangeRuta(Ruta) {
        if (Ruta === 'new-login' || Ruta === '/home')
            this.navCtrl.navigateRoot([Ruta], { replaceUrl: true });
        this.navCtrl.navigateForward(Ruta);
        this._estadistica.RegisterAccion(Ruta);
        this._estadistica.RutaActual = Ruta;
    }
    setShowSpinnerValue(value) {
        this.showSpinnerSubject.next(value);
    }
}
RoutingAppService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function RoutingAppService_Factory() { return new RoutingAppService(i0.ɵɵinject(i1.NavController), i0.ɵɵinject(i2.EstadisticasService), i0.ɵɵinject(i3.Router)); }, token: RoutingAppService, providedIn: "root" });
