import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({
  name: 'sanitize'
})
export class SanitizePipe implements PipeTransform {
    constructor(private sanitizer: DomSanitizer){}

  transform(value: string, ...args: any[]): any {
    if(value){
      const valueAfterConversion = value.replace(/\n/g,"<br>")
      return this.sanitizer.bypassSecurityTrustHtml(valueAfterConversion)
    }
  }

}
