import { CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Card1Component } from './components/cards/card1/card1.component';
import { IonicModule } from '@ionic/angular';
import { List1Component } from './components/list/list1/list1.component';
import { List2Component } from './components/list/list2/list2.component';
import { FooterToolbarComponent } from './components/footer-toolbar/footer-toolbar.component';
import { TranslateModule } from '@ngx-translate/core';
import { MapComponent } from './components/map/map.component';
import { PushMessageListComponent } from './components/push-message-list/push-message-list.component';
import { ClubHeaderComponent } from './components/club-header/club-header.component';
import { BtnsBottomComponent } from './components/btns-bottom/btns-bottom.component';
import { Card2Component } from './components/cards/card2/card2.component';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { List3Component } from './components/list/list3/list3.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ChatbotComponent } from './components/chatbot/chatbot.component';
import { Card3Component } from './components/cards/card3/card3.component';
import { ModalLocationComponent } from './components/modals/modal-location/modal-location.component';
import { Modal1Component } from './components/modals/modal1/modal1.component';
import { ModalShowQrComponent } from './components/modal-show-qr/modal-show-qr.component';
import { Modal2Component } from './components/modals/modal2/modal2.component';
import { AlertBannerComponent } from './components/modals/alert-banner/alert-banner.component';
// import { CallMePage } from '../pages/modal/call-me/call-me.page';
import { PipesModule } from '../pipes/pipes.module';
import { VideoModalComponent } from './components/modals/video-modal/video-modal.component';
//import { ChartComponent } from './components/chart/chart.component';
import { ModalSuccessComponent } from './components/modal-success/modal-success.component';
// import { ChartComponent } from './components/chart/chart.component';
import { SecurityQuestionsComponent } from './components/security-questions/security-questions.component';
import {LazyLoadImageModule} from 'ng-lazyload-image'
import { TabsComponent } from './components/tabs/tabs.component';
import { ModalCompaniesComponent } from './components/modals/modal-companies/modal-companies.component';
// import { UpgradeSectionComponent } from './components/upgrade-section/upgrade-section.component';
import { ResultsSearchComponent } from './components/ResultsSearch/ResultsSearch.component';
import { GalleryPhotosComponent } from './components/GalleryPhotos/GalleryPhotos.component';
import { ProfileOptionsComponent } from './components/ProfileOptions/ProfileOptions.component';
import { OptListComponent } from './components/opt-list/opt-list.component';
import { FilterComponent } from './components/filter/filter.page';
import { SliderComponent } from './components/slider/slider.component';
import { AccordionComponent } from './components/accordion/accordion.component';

const shared = [
  SecurityQuestionsComponent,
  Card1Component,
  List1Component,
  List2Component,
  FooterToolbarComponent,
  MapComponent,
  PushMessageListComponent,
  ClubHeaderComponent,
  BtnsBottomComponent,
  Card2Component,
  SpinnerComponent,
  List3Component,
  ChatbotComponent,
  Card3Component,
  ModalLocationComponent,
  ModalCompaniesComponent,
  Modal1Component,
  ModalShowQrComponent,
  Modal2Component,
  AlertBannerComponent,
  VideoModalComponent,
 // ChartComponent,
  ModalSuccessComponent,
  // ChartComponent
  TabsComponent,
  ResultsSearchComponent,
  GalleryPhotosComponent,
  ProfileOptionsComponent,
  OptListComponent,
  FilterComponent,
  SliderComponent,
  AccordionComponent,
]
@NgModule({
  declarations: [...shared],
  imports: [
    CommonModule,
    IonicModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    PipesModule,
    LazyLoadImageModule
  ],
  exports: [...shared],
  entryComponents: [
    AlertBannerComponent,
    // CallMePage,
    SecurityQuestionsComponent,
    GalleryPhotosComponent,
    ProfileOptionsComponent,
    FilterComponent,
    PushMessageListComponent

  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA,
    NO_ERRORS_SCHEMA
  ]
})
export class SharedModule { }
