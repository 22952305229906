import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SeguridadDatos } from './bscript.service';
import { IUserSae, UserAuthenticationService } from './user-authentication.service';

@Injectable({
  providedIn: 'root'
})
export class LogErrorsService {
  private _errors: string[] = [];

  constructor(
    private _http: HttpClient,
    private security: SeguridadDatos,
    // private authentication: UserAuthenticationService
  ) {

  }

  async insertarError(message: any) {

    if (typeof message === "string") {
      if (!!this._errors.length && (this._errors[this._errors.length - 1] === message)) {
        return;
      }
      this._errors.push(message)
      let date: any = new Date();
      date = date.getUTCFullYear() + '-' + ('00' + (date.getUTCMonth() + 1)).slice(-2) + '-' + ('00' + date.getUTCDate()).slice(-2) + ' ' + ('00' + date.getUTCHours()).slice(-2) + ':' + ('00' + date.getUTCMinutes()).slice(-2) + ':' + ('00' + date.getUTCSeconds()).slice(-2);

      // const user: IUserSae | null = this.authentication.getUser() || null;
      // const body = {
      //   id: "",
      //   cedula: user ? user.cedula : 'No hay usuario',
      //   fecha: date,
      //   errorMessage: message
      // }
      // try {
      //   const httpOptions = {
      //     headers: new HttpHeaders({
      //       'TokenAuthPlataform':  environment.TokenBackendFull,
      //       'Authorization': 'Basic ' + btoa(environment.UserBackendFull+':'+environment.PasswordBackendFull),
      //       'db': this.security.encrypt(environment.NameBD),
      //       'table': this.security.encrypt('tmLogs'),
      //       'x-keys-to-add-id': '["id"]',
      //       'x-keys-of-arrays': '[]',
      //       'x-relations': 'false',
      //       'Content-Type': 'application/json'
      //     })
      //   }

      //   //await this._http.post(`${environment.apiDbMaps}create-info/tmLogs`, body, { headers }).toPromise();
      //   await this._http.post(`${environment.ApiBackendThomas}create-info`, body, httpOptions).subscribe((ResLog: any) => {
      //     //Data guardada con exito 
      //   }, err => { console.error(err) })

      // } catch (error) {
      //   console.error(error)
      // }
    }
  }
}
