export class ExtractUrl {
    constructor() {

    }

    static GetParams(url: string): {[key: string]: string} {
        const indexSearch = url.indexOf("?");
        const params: {[key: string]: string} = { };

        if(indexSearch !== -1) {
            const nameWithValue = url.slice(indexSearch+1, url.length).split("&").map(text => decodeURIComponent(text).split("="));

            for(let nameWithValueItem of nameWithValue) {
                const [name, ...values] = nameWithValueItem;
                const value = values.join("=");

                params[name] = value; // guardar su nombre y valor;
            }
        }

        return params;
    }

    static GetParamsThisPage(): {[key: string]: string} {
      const url = new URL(window.location.href);
      const data: {[key: string]: any} = {};
      url.searchParams.forEach((value, key) => {
        data[key] = value;
      });
        return data;
    }
}
