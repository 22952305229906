import { Router } from '@angular/router';
import { LocalStorageService } from '../../services/local-storage.service';
import { RoutingAppService } from '../../services/routing-app.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "../../services/local-storage.service";
import * as i3 from "../../services/routing-app.service";
export class HomeGuard {
    constructor(router, _localStorageService, _routing) {
        this.router = router;
        this._localStorageService = _localStorageService;
        this._routing = _routing;
    }
    canActivate(next, state) {
        const user = this._localStorageService.get('auth-user');
        if (!user) {
            this._routing.ChangeRuta('/');
            return false;
        }
        return true;
    }
}
HomeGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function HomeGuard_Factory() { return new HomeGuard(i0.ɵɵinject(i1.Router), i0.ɵɵinject(i2.LocalStorageService), i0.ɵɵinject(i3.RoutingAppService)); }, token: HomeGuard, providedIn: "root" });
