import * as tslib_1 from "tslib";
import { EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { LogErrorsService } from './log-errors.service';
import { SeguridadDatos } from './bscript.service';
import axios from "axios";
// import { PlanesInterface } from '../interfaces/planes.interface';
import { dbFullDataBase } from '../lib/dbfullv2';
import { LocalStorageService } from './local-storage.service';
import { MenuController, NavController } from '@ionic/angular';
import { ClearCacheService } from './clear-cache.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./bscript.service";
import * as i3 from "./log-errors.service";
import * as i4 from "./local-storage.service";
import * as i5 from "@ionic/angular";
import * as i6 from "./clear-cache.service";
export class ConsultasService {
    constructor(
    // private push: PushMessagesService,
    http, security, _logs, enc, _localStorageService, menu, 
    // private _routing: RoutingAppService,
    cache, navCtrl) {
        this.http = http;
        this.security = security;
        this._logs = _logs;
        this.enc = enc;
        this._localStorageService = _localStorageService;
        this.menu = menu;
        this.cache = cache;
        this.navCtrl = navCtrl;
        this.ChangeEmail = new EventEmitter();
        this.data$ = new EventEmitter(); // EventEmitter para notifcar
        this.MenuPersonalized = new EventEmitter();
        this.notifications = [];
        this.Serial = "";
        this.dbfull = new dbFullDataBase('saeplus_conexven');
        this.dbfull2 = new dbFullDataBase('thomas_colas');
        this.logoutEmitter = new EventEmitter();
        this.menuOpcionesReference = [];
    }
    EmiterNotify(data) {
        this.data$.emit(data);
    }
    GetAvatar(Phone) {
        return new Promise((resolve, reject) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                // Encabezados de la petición
                const headersData = {
                    directory: "Consultas",
                    method: `GetAvatar`,
                    token: environment.TokenUpdate,
                    platform: "App",
                    Phone: Phone,
                    lic: environment.Lic
                };
                this.security.EncrypDataHash(headersData)
                    .then((headers) => {
                    // Núcleo de la peticion
                    this.http.get(environment.URLApi, { headers }).subscribe((Res) => {
                        resolve(this.security.Descryp(Res));
                    }, error => {
                        this._logs.insertarError(error.message);
                        reject(error);
                    });
                })
                    .catch((err) => console.error(err));
            }
            catch (error) {
                this._logs.insertarError(error.message);
                reject(error);
            }
        }));
    }
    ServerDisponibles(Cedula) {
        return new Promise((resolve, reject) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                // Encabezados de la petición
                const headersData = {
                    directory: "Consultas",
                    method: `ServerFree`,
                    token: environment.TokenUpdate,
                    platform: "App",
                    id: Cedula
                };
                this.security.EncrypDataHash(headersData)
                    .then((headers) => {
                    // Núcleo de la peticion
                    this.http.get(environment.URLApi, { headers }).subscribe((data) => {
                        resolve(this.security.Descryp(data));
                    }, (error) => {
                        this._logs.insertarError(error.message);
                        reject(error);
                    });
                })
                    .catch((err) => console.error(err));
            }
            catch (error) {
                this._logs.insertarError(error.message);
                reject(error);
            }
        }));
    }
    VersionControl(Cedula, version) {
        return new Promise((resolve, reject) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                // Encabezados de la petición
                const headersData = {
                    directory: "Consultas",
                    method: `ControlV`,
                    token: environment.TokenUpdate,
                    platform: "App",
                    Cedula,
                    version
                };
                this.security.EncrypDataHash(headersData)
                    .then((headers) => {
                    // Núcleo de la peticion
                    this.http.get(environment.URLApi, { headers }).subscribe((data) => {
                        data = this.security.Descryp(data);
                        this.UrlUpdate = data.url;
                        resolve(data);
                    }, (error) => {
                        this._logs.insertarError(error.message);
                        reject(error);
                    });
                })
                    .catch((err) => console.error(err));
            }
            catch (error) {
                this._logs.insertarError(error.message);
                reject(error);
            }
        }));
    }
    GetMenu() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return new Promise((resolve, reject) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                try {
                    // Encabezados de la petición
                    const headersData = {
                        directory: "Consultas",
                        method: `MenuApp`,
                        token: environment.TokenUpdate,
                        platform: "App"
                    };
                    yield this.security.EncrypDataHash(headersData)
                        .then((headers) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                        // Núcleo de la peticion
                        this.http.get(environment.URLApi, { headers }).subscribe((data) => {
                            data = this.security.Descryp(data);
                            this.MenuApp = data;
                            resolve(data);
                        }, (error) => {
                            this._logs.insertarError(error.message);
                            reject(error);
                        });
                    }))
                        .catch((err) => console.error(err));
                }
                catch (error) {
                    this._logs.insertarError(error.message);
                    reject(error);
                }
            }));
        });
    }
    SaveDataCLient(DataPost) {
        return new Promise((resolve, reject) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                // Encabezados de la petición
                const headersData = {
                    directory: "Envio",
                    method: "RegsterClienteApp",
                    token: environment.TokenUpdate,
                    platform: "App"
                };
                this.security.EncrypDataHash(headersData).then((headers) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                    this.security.EncrypDataHash(DataPost).then((DataBody) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                        this.http.post(environment.URLApi, DataBody, { headers }).subscribe((response) => {
                            resolve(this.security.Descryp(response));
                        }, (error) => {
                            this._logs.insertarError(error.message);
                            reject(error);
                        });
                    })).catch((err) => console.error(err));
                })).catch((err) => console.error(err));
            }
            catch (error) {
                this._logs.insertarError(error.message);
                reject(error);
            }
        }));
    }
    GetServicesClientByIdContrato(IdContrato) {
        return new Promise((resolve, reject) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                // Encabezados de la petición
                const headersData = {
                    directory: "SAE",
                    method: `ServiciosIdCo`,
                    token: environment.TokenUpdate,
                    platform: "App",
                    id: IdContrato,
                    lic: environment.Lic
                };
                this.security.EncrypDataHash(headersData)
                    .then((headers) => {
                    // Núcleo de la peticion
                    this.http.get(environment.URLApi, { headers }).subscribe((data) => {
                        data = this.security.Descryp(data);
                        const Datafinal = JSON.parse(data);
                        resolve(Datafinal.data.info);
                    }, (error) => {
                        this._logs.insertarError(error.message);
                        reject(error);
                    });
                })
                    .catch((err) => console.error(err));
            }
            catch (error) {
                reject(error);
            }
        }));
    }
    ClienteCRM(Cedula) {
        return new Promise((resolve, reject) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.http.get(`${environment.apiFibexPlay}cedula=${Cedula}`).subscribe((data) => {
                resolve(data);
            }, (error) => {
                reject(error);
            });
        }));
    }
    ClienteSAE(Cedula) {
        return new Promise((resolve, reject) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            // Encabezados de la petición
            const headersData = {
                directory: "SAE",
                method: `AllInfoClientByDNI2`,
                token: environment.TokenUpdate,
                platform: "App",
                id: Cedula,
                lic: environment.Lic
            };
            this.security.EncrypDataHash(headersData)
                .then((headers) => {
                // Núcleo de la peticion
                this.http.get(environment.URLApi, { headers }).subscribe((data) => {
                    resolve(this.security.Descryp(data));
                }, (error) => {
                    reject(error);
                });
            })
                .catch((err) => console.error(err));
        }));
    }
    emailSAE(email) {
        return new Promise((resolve, reject) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            // Encabezados de la petición
            const headersData = {
                directory: "SAE",
                method: `AllInfoClientByEmail`,
                token: environment.TokenUpdate,
                platform: "App",
                id: email,
                lic: environment.Lic
            };
            this.security.EncrypDataHash(headersData)
                .then((headers) => {
                // Núcleo de la peticion
                this.http.get(environment.URLApi, { headers }).subscribe((data) => {
                    resolve(this.security.Descryp(data));
                }, (error) => {
                    reject(error);
                });
            })
                .catch((err) => console.error(err));
        }));
    }
    FormatPhone(Phone) {
        const initPhone = Phone.substring(0, 3);
        if (Phone.charAt(0) === '0' && initPhone != '000') {
            const newphone = `58${Phone.substring(1, Phone.length)}@c.us`;
            return newphone;
        }
        else {
            if (initPhone === '414' || initPhone === '424' || initPhone === '412' || initPhone === '416' || initPhone === '426') {
                return `58${Phone}@c.us`;
            }
            else if (initPhone != '000') {
                return `${Phone}@c.us`;
            }
        }
    }
    HideLetterEmail(Email) {
        const Pos = Email.indexOf('@');
        if (Pos > 1) {
            let HideLetter = '', HideLetter2 = '';
            for (let index = 0; index < Pos - 6; index++) {
                HideLetter = HideLetter + '*';
            }
            for (let index = Pos + 1; index < Email.length - 1; index++) {
                HideLetter2 = HideLetter2 + '*';
            }
            return `${Email.substring(0, 3)}${HideLetter}${Email.substring(Pos - 3, Pos + 1)}${HideLetter2}`;
        }
        else {
            return Email;
        }
    }
    SendMsgwhatsapp(Datos) {
        return new Promise((resolve, reject) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                // Encabezados de la petición
                const headersData = {
                    directory: "Envio",
                    method: "IntermediaryW",
                    token: environment.TokenUpdate,
                    platform: "App",
                };
                this.security.EncrypDataHash(headersData)
                    .then((headers) => {
                    this.security.EncrypDataHash(Datos).then((DataBody) => {
                        this.http.post(environment.URLApi, DataBody, { headers }).subscribe((response) => {
                            resolve(this.security.Descryp(response));
                        }, (error) => {
                            this._logs.insertarError(error.message);
                            reject(error);
                        });
                    })
                        .catch((err) => console.error(err));
                })
                    .catch((err) => console.error(err));
            }
            catch (error) {
                reject(error);
            }
        }));
    }
    getNotify(id_cliente, readed, page) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return new Promise((resolve, reject) => {
                try {
                    environment.tableNotify;
                    this.dbfull2.GET_ANY_QUERY({ query: `
                    SELECT * from ${environment.tableNotify} 
                    where id_cliente = '${id_cliente}' and 
                    ${readed ? 'read_at is not NULL' : 'read_at is NULL'}
                    limit 10 offset ${(page || 0) * 10}` })
                        .then(res => {
                        resolve(res);
                    });
                }
                catch (error) {
                    this._logs.insertarError(error.message);
                    reject(error);
                }
            });
        });
    }
    setNotify(data, date) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return new Promise((resolve, reject) => {
                try {
                    let headers = {
                        'db': this.enc.encrypt(environment.NameBDV2),
                        'table': this.enc.encrypt(environment.tableNotify),
                        'campo': this.enc.encrypt('id'),
                        'x-multiple-update': 'false',
                        'x-elements-obj': '[]',
                        'x-attr-duplicate': '[]',
                        'Authorization': environment.authdbFUll,
                        'Content-Type': 'application/json',
                        'TokenAuthPlataform': environment.TokenBackendFull
                    }, body = {
                        'id': data.id,
                        'read_at': date
                    };
                    axios.put(`${environment.ApiBackendThomas}update-info`, body, { headers: headers }).then((response) => {
                        if (response.status == 200) {
                            this.EmiterNotify(true);
                        }
                        resolve(response);
                    });
                }
                catch (error) {
                    this._logs.insertarError(error.message);
                    reject(error);
                    return {
                        error: true,
                        data: []
                    };
                }
            });
        });
    }
    deleteNotify(data, date) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return new Promise((resolve, reject) => {
                try {
                    let headers = {
                        'db': this.enc.encrypt(environment.NameBDV2),
                        'table': this.enc.encrypt(environment.tableNotify),
                        'campo': this.enc.encrypt('id'),
                        'x-multiple-update': 'false',
                        'x-elements-obj': '[]',
                        'x-attr-duplicate': '[]',
                        'Authorization': environment.authdbFUll,
                        'Content-Type': 'application/json',
                        'TokenAuthPlataform': environment.TokenBackendFull
                    }, body = {
                        'id': data.id,
                        'deleteAt': date
                    };
                    axios.put(`${environment.ApiBackendThomas}update-info`, body, { headers: headers }).then((response) => {
                        if (response.status == 200) {
                            this.EmiterNotify(true);
                        }
                        resolve(response);
                    });
                }
                catch (error) {
                    this._logs.insertarError(error.message);
                    reject(error);
                    return {
                        error: true,
                        data: []
                    };
                }
            });
        });
    }
    checkLastUse(ci) {
        const headers = {
            ci,
            Authorization: 'Basic ' + btoa(`${environment.hash_user}:${environment.hash_password}`),
            TokenAuth: environment.hash_token
        };
        return new Promise((resolve, reject) => {
            try {
                axios.get(`${environment.hash_url}lastUseApp`, { headers })
                    .then(res => resolve(res.data))
                    .catch(error => {
                    console.error(error);
                    reject(error);
                    throw new Error();
                });
            }
            catch (error) {
                console.error(error);
                reject(error);
            }
        });
    }
    createLogSoporteApp(data) {
        return new Promise((resolve, reject) => {
            try {
                const httpOptions = {
                    headers: new HttpHeaders({
                        'TokenAuthPlataform': environment.TokenBackendFull,
                        'Authorization': 'Basic ' + btoa(environment.UserBackendFull + ':' + environment.PasswordBackendFull),
                        'db': this.security.encrypt(environment.NameBD),
                        'table': this.security.encrypt('tmSoporteApp'),
                        'x-keys-to-add-id': '[]',
                        'x-keys-of-arrays': '[]',
                        'x-relations': 'false'
                    })
                };
                this.http.post(`${environment.ApiBackendThomas}create-info`, data, httpOptions).subscribe(res => {
                    resolve(res);
                });
            }
            catch (error) {
                this._logs.insertarError(error.message);
                reject(error);
            }
        });
    }
    authUserCredentials({ dni, usermail, userphone, ask, answer }) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return new Promise((resolve, reject) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                try {
                    // Encabezados de la petición
                    const qHeaders = {
                        directory: 'Auth',
                        method: 'Login'
                    };
                    const data = Object.assign({ dni,
                        usermail,
                        userphone }, (ask && answer ? {
                        ask,
                        answer
                    } : {}));
                    this.security.EncrypDataHash(qHeaders).then((headers) => {
                        this.security.EncrypDataHash(data).then((DataBody) => {
                            this.http.post(environment.URLApi, DataBody, { headers }).subscribe(data => {
                                resolve(this.security.Descryp(data));
                            }, (error) => {
                                this._logs.insertarError(error.message);
                                reject(error);
                            });
                        }).catch((err) => console.error(err));
                    }).catch((err) => console.error(err));
                }
                catch (error) {
                    this._logs.insertarError(error.message);
                    reject(error);
                }
            }));
        });
    }
    GetSaldoCedula(Cedula) {
        return new Promise((resolve, reject) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                // Encabezados de la petición
                const headersData = {
                    directory: "SAE",
                    method: `SaldoCe`,
                    token: environment.TokenUpdate,
                    platform: "App",
                    id: Cedula,
                    lic: environment.Lic
                };
                this.security.EncrypDataHash(headersData)
                    .then((headers) => {
                    // // Núcleo de la peticion
                    this.http.get(environment.URLApi, { headers }).subscribe(data => {
                        data = this.security.Descryp(data);
                        const SaldoD = JSON.parse(data[0]);
                        if (SaldoD.data.info.length > 0) {
                            resolve(SaldoD.data.info);
                        }
                        else {
                            resolve(null);
                        }
                    }, error => {
                        this._logs.insertarError(error.message);
                        reject(error);
                    });
                })
                    .catch((err) => console.error(err));
            }
            catch (error) {
                this._logs.insertarError(error.message);
                reject(error);
            }
        }));
    }
    logoutSession(token) {
        return new Promise((resolve, reject) => {
            try {
                if (token) {
                    // Encabezados de la petición
                    const qHeaders = {
                        directory: 'Auth',
                        method: 'Logout'
                    };
                    const qData = {
                        auth: token
                    };
                    this.security.EncrypDataHash(qHeaders)
                        .then((headers) => {
                        this.security.EncrypDataHash(qData)
                            .then((DataBody) => {
                            this.http.post(environment.URLApi, DataBody, { headers }).subscribe(data => {
                                resolve(data);
                            }, (error) => {
                                this._logs.insertarError(error.message);
                                reject(error);
                            });
                        })
                            .catch(reject);
                    })
                        .catch(reject);
                }
                else {
                    reject(new Error("Token de authenticacion requerido"));
                }
            }
            catch (error) {
                this._logs.insertarError(error.message);
                reject(error);
            }
        });
    }
    Logout() {
        const auth = this._localStorageService.get("auth");
        //Borro el serial de su equipo pq si inicia con otra cuenta esto debe ser reseteado
        this.Serial = "";
        this.menu.close();
        this.logoutEmitter.emit();
        this.logoutSession(auth)
            .then(console.log)
            .catch(console.error)
            .finally(() => {
            localStorage.clear();
            this._localStorageService.remove('fav_products');
            this.cache.clear();
            this.navCtrl.navigateRoot(["new-login"], { replaceUrl: true });
        });
    }
}
ConsultasService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ConsultasService_Factory() { return new ConsultasService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.SeguridadDatos), i0.ɵɵinject(i3.LogErrorsService), i0.ɵɵinject(i2.SeguridadDatos), i0.ɵɵinject(i4.LocalStorageService), i0.ɵɵinject(i5.MenuController), i0.ɵɵinject(i6.ClearCacheService), i0.ɵɵinject(i5.NavController)); }, token: ConsultasService, providedIn: "root" });
