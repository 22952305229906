import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { LogErrorsService } from './log-errors.service';
import { SeguridadDatos } from './bscript.service';
import { MenuOptInterface } from '../interfaces/menuOpt.interface';
import axios, { AxiosResponse } from "axios";
// import { PlanesInterface } from '../interfaces/planes.interface';
import { dbFullDataBase } from '../lib/dbfullv2';
import { map } from 'rxjs/operators';
import { LocalStorageService } from './local-storage.service';
import { MenuController, NavController } from '@ionic/angular';
import { ClearCacheService } from './clear-cache.service';
import { IUserSae} from './user-authentication.service';
import { PushMessagesService } from './push-messages.service';
import { Pages } from '../interfaces/pages';


@Injectable({
    providedIn: 'root'
})
export class ConsultasService {
    public UrlUpdate: any
    public MenuApp: MenuOptInterface[];
    ChangeEmail: EventEmitter<IUserSae> = new EventEmitter();
    data$: EventEmitter<any> = new EventEmitter();   // EventEmitter para notifcar
    MenuPersonalized: EventEmitter<any> = new EventEmitter();
    notifications: any = [];
    public Serial: string = "";
    dbfull: dbFullDataBase = new dbFullDataBase('saeplus_conexven');
    dbfull2: dbFullDataBase = new dbFullDataBase('thomas_colas');
    logoutEmitter: EventEmitter<void> = new EventEmitter();


    constructor(
        // private push: PushMessagesService,
        public http: HttpClient,
        private security: SeguridadDatos,
        private _logs: LogErrorsService,
        private enc: SeguridadDatos,
        private _localStorageService: LocalStorageService,
        public menu: MenuController,
        // private _routing: RoutingAppService,
        private cache: ClearCacheService,
        private navCtrl: NavController,
    ) { 
    }
    public menuOpcionesReference: Pages[] = [];
    EmiterNotify(data) {
        this.data$.emit(data)
    }

    GetAvatar(Phone: any) {
        return new Promise(async (resolve: any, reject: any) => {
            try {

                // Encabezados de la petición
                const headersData = {
                    directory: "Consultas",
                    method: `GetAvatar`,
                    token: environment.TokenUpdate,
                    platform: "App",
                    Phone: Phone,
                    lic: environment.Lic
                };

                this.security.EncrypDataHash(headersData)
                    .then((headers: any) => { // encabezados encriptados

                        // Núcleo de la peticion
                        this.http.get(environment.URLApi, { headers }).subscribe((Res: any) => {
                            resolve(this.security.Descryp(Res));
                        }, error => {
                            this._logs.insertarError(error.message);
                            reject(error)
                        })

                    })
                    .catch((err) => console.error(err))

            } catch (error) {
                this._logs.insertarError(error.message);
                reject(error)
            }
        })

    }
    ServerDisponibles(Cedula: any) {
        return new Promise(async (resolve: any, reject: any) => {
            try {

                // Encabezados de la petición
                const headersData = {
                    directory: "Consultas",
                    method: `ServerFree`,
                    token: environment.TokenUpdate,
                    platform: "App",
                    id: Cedula
                };

                this.security.EncrypDataHash(headersData)
                    .then((headers: any) => { // encabezados encriptados

                        // Núcleo de la peticion
                        this.http.get(environment.URLApi, { headers }).subscribe((data: any) => {
                            resolve(this.security.Descryp(data))
                        }, (error: any) => {
                            this._logs.insertarError(error.message);
                            reject(error)
                        })

                    })
                    .catch((err) => console.error(err))

            } catch (error) {
                this._logs.insertarError(error.message);
                reject(error)
            }
        })
    }
    VersionControl(Cedula: any, version: any) {
        return new Promise(async (resolve: any, reject: any) => {
            try {

                // Encabezados de la petición
                const headersData = {
                    directory: "Consultas",
                    method: `ControlV`,
                    token: environment.TokenUpdate,
                    platform: "App",
                    Cedula,
                    version
                };

                this.security.EncrypDataHash(headersData)
                    .then((headers: any) => { // encabezados encriptados

                        // Núcleo de la peticion
                        this.http.get(environment.URLApi, { headers }).subscribe((data: any) => {
                            data = this.security.Descryp(data)
                            this.UrlUpdate = data.url
                            resolve(data);
                        }, (error: any) => {
                            this._logs.insertarError(error.message);
                            reject(error)
                        })

                    })
                    .catch((err) => console.error(err))

            } catch (error) {
                this._logs.insertarError(error.message);
                reject(error)
            }
        })
    }

    async GetMenu(): Promise<MenuOptInterface[]> {
        return new Promise(async (resolve: any, reject: any) => {
            try {

                // Encabezados de la petición
                const headersData = {
                    directory: "Consultas",
                    method: `MenuApp`,
                    token: environment.TokenUpdate,
                    platform: "App"
                };

                await this.security.EncrypDataHash(headersData)
                    .then(async (headers: any) => { // encabezados encriptados

                        // Núcleo de la peticion
                        this.http.get(environment.URLApi, { headers }).subscribe((data: any) => {
                            data = this.security.Descryp(data)
                            this.MenuApp = data
                            resolve(data)
                        }, (error: any) => {
                            this._logs.insertarError(error.message);
                            reject(error)
                        })

                    })
                    .catch((err) => console.error(err))

            } catch (error) {
                this._logs.insertarError(error.message);
                reject(error)
            }
        })
    }
    SaveDataCLient(DataPost: any) {
        return new Promise(async (resolve: any, reject: any) => {
            try {

                // Encabezados de la petición
                const headersData = {
                    directory: "Envio",
                    method: "RegsterClienteApp",
                    token: environment.TokenUpdate,
                    platform: "App"
                };

                this.security.EncrypDataHash(headersData).then(async (headers: any) => { // encabezados encriptados
                    this.security.EncrypDataHash(DataPost).then(async (DataBody: any) => { // encabezados encriptados
                        this.http.post(environment.URLApi, DataBody, { headers }).subscribe((response: any) => {
                            resolve(this.security.Descryp(response))
                        }, (error) => {
                            this._logs.insertarError(error.message);
                            reject(error);
                        });
                    }).catch((err) => console.error(err));
                }).catch((err) => console.error(err));

            } catch (error) {
                this._logs.insertarError(error.message);
                reject(error)
            }
        })
    }

    GetServicesClientByIdContrato(IdContrato: String) {
        return new Promise(async (resolve: any, reject: any) => {
            try {

                // Encabezados de la petición
                const headersData = {
                    directory: "SAE",
                    method: `ServiciosIdCo`,
                    token: environment.TokenUpdate,
                    platform: "App",
                    id: IdContrato,
                    lic: environment.Lic
                };

                this.security.EncrypDataHash(headersData)
                    .then((headers: any) => { // encabezados encriptados

                        // Núcleo de la peticion
                        this.http.get(environment.URLApi, { headers }).subscribe((data: any) => {
                            data = this.security.Descryp(data)
                            const Datafinal = JSON.parse(data)
                            resolve(Datafinal.data.info)

                        }, (error: any) => {
                            this._logs.insertarError(error.message);
                            reject(error)
                        })

                    })
                    .catch((err) => console.error(err))

            } catch (error) {
                reject(error)
            }
        })
    }
    ClienteCRM(Cedula: any) {
        return new Promise(async (resolve: any, reject: any) => {
            this.http.get(`${environment.apiFibexPlay}cedula=${Cedula}`).subscribe((data: any) => {
                resolve(data)
            }, (error: any) => {
                reject(error)
            })
        })
    }

    ClienteSAE(Cedula: any) {
        return new Promise(async (resolve: any, reject: any) => {
            // Encabezados de la petición
            const headersData = {
                directory: "SAE",
                method: `AllInfoClientByDNI2`,
                token: environment.TokenUpdate,
                platform: "App",
                id: Cedula,
                lic: environment.Lic
            };

            this.security.EncrypDataHash(headersData)
                .then((headers: any) => { // encabezados encriptados

                    // Núcleo de la peticion
                    this.http.get(environment.URLApi, { headers }).subscribe((data: any) => {
                        resolve(this.security.Descryp(data))
                    }, (error: any) => {
                        reject(error)
                    })

                })
                .catch((err) => console.error(err))
        })
    }
    emailSAE(email: string) {
        return new Promise(async (resolve: any, reject: any) => {
            // Encabezados de la petición
            const headersData = {
                directory: "SAE",
                method: `AllInfoClientByEmail`,
                token: environment.TokenUpdate,
                platform: "App",
                id: email,
                lic: environment.Lic
            };

            this.security.EncrypDataHash(headersData)
                .then((headers: any) => { // encabezados encriptados

                    // Núcleo de la peticion
                    this.http.get(environment.URLApi, { headers }).subscribe((data: any) => {
                        resolve(this.security.Descryp(data))
                    }, (error: any) => {
                        reject(error)
                    })

                })
                .catch((err) => console.error(err))
        })
    }

    FormatPhone(Phone: any) {
        const initPhone = Phone.substring(0, 3)
        if (Phone.charAt(0) === '0' && initPhone != '000') {
            const newphone = `58${Phone.substring(1, Phone.length)}@c.us`
            return newphone
        } else {

            if (initPhone === '414' || initPhone === '424' || initPhone === '412' || initPhone === '416' || initPhone === '426') {
                return `58${Phone}@c.us`
            } else if (initPhone != '000') {
                return `${Phone}@c.us`
            }
        }
    }
    HideLetterEmail(Email: any) {
        const Pos = Email.indexOf('@')

        if (Pos > 1) {

            let HideLetter = '', HideLetter2 = ''

            for (let index = 0; index < Pos - 6; index++) {
                HideLetter = HideLetter + '*'
            }

            for (let index = Pos + 1; index < Email.length - 1; index++) {
                HideLetter2 = HideLetter2 + '*'
            }

            return `${Email.substring(0, 3)}${HideLetter}${Email.substring(Pos - 3, Pos + 1)}${HideLetter2}`

        } else {
            return Email
        }

    }
    SendMsgwhatsapp(Datos: any) {
        return new Promise(async (resolve: any, reject: any) => {
            try {
                // Encabezados de la petición
                const headersData = {
                    directory: "Envio",
                    method: "IntermediaryW",
                    token: environment.TokenUpdate,
                    platform: "App",
                };

                this.security.EncrypDataHash(headersData)
                    .then((headers: any) => { // encabezados encriptados
                        this.security.EncrypDataHash(Datos).then((DataBody: any) => { // encabezados encriptados
                            this.http.post(environment.URLApi, DataBody, { headers }).subscribe((response: any) => {
                                resolve(this.security.Descryp(response))
                            }, (error) => {
                                this._logs.insertarError(error.message);
                                reject(error);
                            });
                        })
                            .catch((err) => console.error(err));
                    })
                    .catch((err) => console.error(err));

            } catch (error) {
                reject(error)
            }
        })
    }
    async getNotify(id_cliente: string, readed: boolean, page?: number) {
        return new Promise((resolve, reject) => {
            try {
                environment.tableNotify
                this.dbfull2.GET_ANY_QUERY({ query: `
                    SELECT * from ${environment.tableNotify} 
                    where id_cliente = '${id_cliente}' and 
                    ${readed ? 'read_at is not NULL' : 'read_at is NULL'}
                    limit 10 offset ${(page || 0) * 10}` })
                    .then(res => {
                        resolve(res)
                    })
            } catch (error) {
                this._logs.insertarError(error.message);
                reject(error)
            }
        })
    }

    async setNotify(data: any, date: any) {
        return new Promise((resolve, reject) => {
            try {
                let headers = {
                    'db': this.enc.encrypt(environment.NameBDV2),
                    'table': this.enc.encrypt(environment.tableNotify),
                    'campo': this.enc.encrypt('id'),
                    'x-multiple-update': 'false',
                    'x-elements-obj': '[]',
                    'x-attr-duplicate': '[]',
                    'Authorization': environment.authdbFUll,
                    'Content-Type': 'application/json',
                    'TokenAuthPlataform': environment.TokenBackendFull
                },
                    body = {
                        'id': data.id,
                        'read_at': date
                    }
                axios.put(`${environment.ApiBackendThomas}update-info`, body, { headers: headers }).then((response: any) => {
                    if (response.status == 200) {
                        this.EmiterNotify(true);
                    }
                    resolve(response);
                })

            }
            catch (error) {
                this._logs.insertarError(error.message);
                reject(error);
                return {
                    error: true,
                    data: []
                }
            }
        });
    }
    async deleteNotify(data: any, date: any) {
        return new Promise((resolve, reject) => {
            try {
                let headers = {
                    'db': this.enc.encrypt(environment.NameBDV2),
                    'table': this.enc.encrypt(environment.tableNotify),
                    'campo': this.enc.encrypt('id'),
                    'x-multiple-update': 'false',
                    'x-elements-obj': '[]',
                    'x-attr-duplicate': '[]',
                    'Authorization': environment.authdbFUll,
                    'Content-Type': 'application/json',
                    'TokenAuthPlataform': environment.TokenBackendFull
                },
                    body = {
                        'id': data.id,
                        'deleteAt': date
                    }
                axios.put(`${environment.ApiBackendThomas}update-info`, body, { headers: headers }).then((response: any) => {
                    if (response.status == 200) {
                        this.EmiterNotify(true);
                    }
                    resolve(response);
                })

            }
            catch (error) {
                this._logs.insertarError(error.message);
                reject(error);
                return {
                    error: true,
                    data: []
                }
            }
        });
    }
    checkLastUse(ci: string) {
        const headers = {
            ci,
            Authorization: 'Basic ' + btoa(`${environment.hash_user}:${environment.hash_password}`),
            TokenAuth: environment.hash_token
        }

        return new Promise((resolve, reject) => {
            try {
                axios.get(`${environment.hash_url}lastUseApp`, { headers })
                    .then(res => resolve(res.data))
                    .catch(error => {
                        console.error(error)
                        reject(error)
                        throw new Error()
                    })

            } catch (error) {
                console.error(error)
                reject(error)
            }
        })

    }

    createLogSoporteApp(data: any) {
        return new Promise((resolve, reject) => {
            try {
                const httpOptions = {
                    headers: new HttpHeaders({
                        'TokenAuthPlataform': environment.TokenBackendFull,
                        'Authorization': 'Basic ' + btoa(environment.UserBackendFull + ':' + environment.PasswordBackendFull),
                        'db': this.security.encrypt(environment.NameBD),
                        'table': this.security.encrypt('tmSoporteApp'),
                        'x-keys-to-add-id': '[]',
                        'x-keys-of-arrays': '[]',
                        'x-relations': 'false'
                    })
                }

                this.http.post(`${environment.ApiBackendThomas}create-info`, data, httpOptions).subscribe(res => {
                    resolve(res)
                });

            } catch (error) {
                this._logs.insertarError(error.message);
                reject(error)
            }
        })
    }

    async authUserCredentials({ dni, usermail, userphone, ask, answer }: { dni: string, usermail: string, userphone: string, ask?: string, answer?: string }) {

        return new Promise(async (resolve: any, reject: any) => {
            try {
                // Encabezados de la petición
                const qHeaders = {
                    directory: 'Auth',
                    method: 'Login'
                }
                const data = {
                    dni,
                    usermail,
                    userphone,
                    ...(ask && answer ? {
                        ask,
                        answer
                    } : {})
                };

                this.security.EncrypDataHash(qHeaders).then((headers: any) => { // encabezados encriptados
                    this.security.EncrypDataHash(data).then((DataBody: any) => { // encabezados encriptados
                        this.http.post(environment.URLApi, DataBody, { headers }).subscribe(data => {
                            resolve(this.security.Descryp(data))
                        }, (error: any) => {
                            this._logs.insertarError(error.message);
                            reject(error)
                        })
                    }).catch((err) => console.error(err));
                }).catch((err) => console.error(err));

            } catch (error) {
                this._logs.insertarError(error.message);
                reject(error)
            }
        })


    } 
    GetSaldoCedula(Cedula: any) {
        return new Promise(async (resolve: any, reject: any) => {
            try {

                // Encabezados de la petición
                const headersData = {
                    directory: "SAE",
                    method: `SaldoCe`,
                    token: environment.TokenUpdate,
                    platform: "App",
                    id: Cedula,
                    lic: environment.Lic
                };

                this.security.EncrypDataHash(headersData)
                    .then((headers: any) => { // encabezados encriptados
                        // // Núcleo de la peticion
                        this.http.get(environment.URLApi, { headers }).subscribe(data => {
                            data = this.security.Descryp(data)
                            const SaldoD = JSON.parse(data[0])
                            if (SaldoD.data.info.length > 0) {
                                resolve(SaldoD.data.info)
                            } else {
                                resolve(null)
                            }

                        }, error => {
                            this._logs.insertarError(error.message);
                            reject(error)
                        });

                    })
                    .catch((err) => console.error(err))


            } catch (error) {
                this._logs.insertarError(error.message);
                reject(error)
            }
        })
    }

    logoutSession(token: string) {
        return new Promise((resolve: any, reject: any) => {
            try {
                if(token) {
                    // Encabezados de la petición
                    const qHeaders = {
                        directory: 'Auth',
                        method: 'Logout'
                    }
                    const qData = {
                        auth: token
                    }
    
                    this.security.EncrypDataHash(qHeaders)
                        .then((headers: any) => { // encabezados encriptados
                            this.security.EncrypDataHash(qData)
                                .then((DataBody: any) => { // encabezados encriptados
                                    this.http.post(environment.URLApi, DataBody, { headers }).subscribe(data => {
                                        resolve(data)
                                    }, (error: any) => {
                                        this._logs.insertarError(error.message);
                                        reject(error)
                                    })
                                })
                                .catch(reject)
                        })
                        .catch(reject);
                }
                else {
                    reject(new Error("Token de authenticacion requerido"));
                }
            } catch (error) {
                this._logs.insertarError(error.message);
                reject(error)
            }
        })
    }

    Logout() {
        const auth = this._localStorageService.get("auth");
        //Borro el serial de su equipo pq si inicia con otra cuenta esto debe ser reseteado
        this.Serial = "";
        this.menu.close()
        this.logoutEmitter.emit();
        this.logoutSession(auth)
        .then(console.log)
        .catch(console.error)
        .finally(()=>{
            localStorage.clear()
            this._localStorageService.remove('fav_products');
            this.cache.clear()
            this.navCtrl.navigateRoot( ["new-login"], {replaceUrl:true})
            })
    }
}