import { Component, Input, OnDestroy, OnInit } from '@angular/core';

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss'],
})
export class SpinnerComponent implements OnInit, OnDestroy {

  @Input() showSpinner: boolean = false;
  @Input() fullScreen: boolean = false;
  @Input() color: string = 'secondary'
  @Input() mode: string = ''

  constructor(
  ) {
  }

  ngOnInit() {}

  ngOnDestroy(): void {
  }

}
