import * as tslib_1 from "tslib";
import * as i0 from "@angular/core";
var CryptoJS = require("crypto-js");
import { environment } from "../../environments/environment";
export class SeguridadDatos {
    constructor() {
        this.key = 'T0rNaDoK4tr1Na?RTgcNmhKU=';
    }
    //Encripta la los datos
    encrypt(str) {
        let encrypted = CryptoJS.AES.encrypt(str, this.key, {
            keySize: 16,
            mode: CryptoJS.mode.ECB,
            padding: CryptoJS.pad.Pkcs7,
        });
        return encrypted.toString();
    }
    EncrypObj(obj) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const newObj = {};
            for (let keyName in obj) {
                if (typeof obj[keyName] === "number") {
                    obj[keyName] = obj[keyName].toString();
                }
                if (obj[keyName] instanceof Object) {
                    obj[keyName] = yield this.EncrypDataHash(obj[keyName]);
                }
                else
                    newObj[keyName] = String(this.encrypt(obj[keyName]));
            }
            return newObj;
        });
    }
    EncrypArray(Data) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            for (let index = 0; index < Data.length; index++) {
                Data[index] = yield this.EncrypDataHash(Data[index]);
                if (index === Data.length - 1) {
                    return Data;
                }
            }
        });
    }
    // Encripta los datos que que deben ser enviados en una peticion
    EncrypDataHash(Datos) {
        return new Promise((resolve, reject) => {
            try {
                // if (Datos instanceof Array) {
                //     resolve(this.EncrypArray(Datos));
                // } else {
                //     resolve(this.EncrypObj(Datos));
                // }
                Object.entries(Datos).forEach(([keyOriginal, valueKey], index) => {
                    var Tamano = Object.keys(Datos);
                    if (typeof valueKey != "number" && valueKey != "" && valueKey != undefined && valueKey != null) {
                        const Encrypt = this.encrypt(valueKey); //Encripto
                        Datos[keyOriginal] = Encrypt;
                    }
                    if (index == Tamano.length - 1) {
                        resolve(Datos);
                    }
                });
            }
            catch (error) {
                reject(error);
            }
        });
    }
    Descryp(Datos) {
        let Data = CryptoJS.AES.decrypt(Datos.message, this.key, {
            keySize: 16,
            mode: CryptoJS.mode.ECB,
            padding: CryptoJS.pad.Pkcs7,
        }).toString(CryptoJS.enc.Utf8);
        if (typeof Data == "string") {
            return JSON.parse(Data);
        }
        else {
            return Data;
        }
    }
    // public encrypt_string(value: string,length: number ){
    //     const hash = createHash('sha1');
    //     const update_hash = hash.update(value);
    //     const hashed_value = update_hash.digest('hex');
    //     return hashed_value.toString();
    //     // const value_encrypted = CryptoJS.AES.encrypt(value, this.key, {
    //     //     keySize: length,
    //     //     mode: CryptoJS.mode.ECB,
    //     //     padding: CryptoJS.pad.Pkcs7,
    //     // });
    //     // return value_encrypted.toString();
    // }
    btoa(str) {
        return btoa(str);
    }
    Descrypt2(obj) {
        try {
            if (obj instanceof Object) {
                const newObj = obj instanceof Array ? [] : {};
                for (let keyName in obj) {
                    newObj[keyName] = this.Descrypt2(obj[keyName]);
                }
                return newObj;
            }
            else if (typeof obj === "string") {
                return String(this.DecryptDatos(obj));
            }
            return obj;
        }
        catch (error) {
            console.error(error);
        }
    }
    /**
 * Permite desencriptar un texto
 * @param Data string descriptado
 * @returns data desencriptado
 */
    DecryptDatos(str) {
        try {
            return CryptoJS.AES.decrypt(str, environment.key, {
                keySize: 16,
                mode: CryptoJS.mode.ECB,
                padding: CryptoJS.pad.Pkcs7,
            }).toString(CryptoJS.enc.Utf8);
        }
        catch (error) {
            console.error(error);
        }
    }
}
SeguridadDatos.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SeguridadDatos_Factory() { return new SeguridadDatos(); }, token: SeguridadDatos, providedIn: "root" });
