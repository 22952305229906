import { Component, Input, OnInit, Output, EventEmitter, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AlertController, IonSelect, NavController } from '@ionic/angular';
import { CouponProductInterface, ProductInterface } from '../../../../interfaces/product.interface';
import { HelperService } from '../../../../services/helper.service';
import { ClubFibexFeedingService } from '../../../../services/club-fibex-feeding.service';
import { IUserSae, UserAuthenticationService } from '../../../../services/user-authentication.service';
import { UserInterface } from '../../../../interfaces/userInfo';

@Component({
  selector: 'app-list2',
  templateUrl: './list2.component.html',
  styleUrls: ['./list2.component.scss'],
})
export class List2Component implements OnInit {
  // @ViewChild(`${this.item.id}`, {static: true}) itemElement: Element;
  @ViewChild('selector', { static: false }) selector: IonSelect
  @Input() item: CouponProductInterface;
  @Input() tabID: string = 'withoutUsing';
  @Input() coupons: CouponProductInterface[] = [];
  @Output() cuponCanjeado: EventEmitter<CouponProductInterface> = new EventEmitter();
  @Output() onClickEmit: EventEmitter<CouponProductInterface> = new EventEmitter();
  public showCouponSelector: boolean = false;
  public user: IUserSae;
  public totalCoupons: CouponProductInterface[] = [];
  public showModal: boolean = false;
  public currentItem: CouponProductInterface;
  public totalMissingDays: number = 0;
 public isPromotionExpired: boolean = false;
  constructor(
    private router: Router,
    private helper: HelperService,
    private clubService: ClubFibexFeedingService,
    private alertCtrl: AlertController,
    private navCtrl: NavController,
    private athentication: UserAuthenticationService,
  ) {
    try {
      this.user = this.athentication.getUser();
    } catch (error) {
      console.error(error);
    }

   }

  ngOnInit() {
    this.CalcMissingDays();
    this.totalCoupons = this.coupons.filter(eItem => eItem.id === this.item.id && eItem.usado === 0)
    this.currentItem = this.item;
  }

  itemAccordion(itemId: string) {//Codigo para el acordion de cupones
    if (this.tabID === "withoutUsing") {

      const allInputs: any = document.querySelectorAll('.check_input_accordion');
      const divContent: any = document.querySelectorAll('.div_content');
      allInputs.forEach((input: any, i) => {//Cierra el contenido de los demas cupones
        if (input.id != itemId) { input.checked = false; divContent[i].classList.remove('acordion_active') }
      })

      const inputCheck: any = document.getElementById(`${itemId}`);
      const content = document.getElementById(`div_content_${itemId}`);
      if (inputCheck.checked) content.classList.add('acordion_active') //Abre el contenido del cupon seleccionado
      else content.classList.remove('acordion_active')
    }
  }


  goToRestDetail(id: string, showCoupon: boolean = false) {
    // this.currentItem = this.totalCoupons[0]
    // this.showModal = true;
    this.router.navigate(['club-fibex/product-details', id, showCoupon])
  }

  isContentActive(itemId: string): boolean {
    const inputCheck: any = document.getElementById(`${itemId}`);
    return (inputCheck && inputCheck.checked ? inputCheck && inputCheck.checked : false)
  }

  public itemSelected(): void {
    // this.currentItem = this.totalCoupons[+e.target.value]
    if (this.totalCoupons && this.totalCoupons.length > 1) this.handleShowCouponSelector(true)
    else {
      this.showModal = true;
      this.selectCoupon(this.item);
    }
  }

  public handleShowCouponSelector(value: boolean): void {
    if(value === true && this.totalCoupons.length > 1) this.showCouponSelector = value;
    else {
      this.showCouponSelector = false;
    }
  }

  openSelector() {
    this.selector.open();
  }

  private CalcMissingDays(): void {
    let FechaFinComparar = new Date(this.item.FechaFin).getTime();
    let FechaActual = new Date().getTime();
    let diff = FechaFinComparar - FechaActual;
    this.totalMissingDays = Math.round(diff / (1000 * 60 * 60 * 24))
    this.isPromotionExpired = FechaActual > FechaFinComparar;
  }

  public goToCuponSuccessed() {
    this.showModal = false;
    this.cuponCanjeado.emit(this.currentItem);
  }

  public selectCoupon(coupon: CouponProductInterface): void {
    if(coupon.TipoCanjeo === 3) {
      this.onClickEmit.emit(this.item);
    }
    else {
      this.currentItem = coupon;
      this.handleShowCouponSelector(false)
      this.showModal = true;
    }
  }

  public subtractToNumber(number1: unknown, number2: unknown): number {
    return Number(number1) - Number(number2);
  }
  public getPrice(number: unknown){
    return Number(number);
  }
  async downloadBill(){
    const { QR, QRurl, price, name, description, idAbonado} = this.currentItem;

    try {
        const result = await this.clubService.generateQRBill({ NombreProducto: name, description, idAbonado, price, Codigo: QR, Cupon: QRurl })
        if(result){
            this.showModal = false
            this.alertCtrl.create({
                header: 'Su cupón ha sido generado con éxito',
                message:"Puede encontrarlo dentro de la carpeta descargas en su dispositivo",
                backdropDismiss: false,
                buttons: [
                    {
                    text: 'Aceptar',
                    role: 'cancel',
                    cssClass: 'secondary cust',
                    }
                ]
            }).then(res => res.present())
            // await this.downloadBase64(result.operation[1].pdf64)
            // await this.downloadPDF(result.operation.secure_url)
            this.helper.setIframeToRender({url: result.operation.secure_url, title: "Cupón generado"});
            this.navCtrl.navigateForward('/iframe-renderer');

        }else{
            throw new Error()
        }
        
    } catch (error) {
        console.error(error)
    }

  }

  downloadBase64(base64: string){
    return new Promise((resolve: any, reject: any) => {
        try {
            const link_source = `data:application/pdf;base64,${base64}`;
            const download_link = document.createElement("a");
            const filename = "cupon.pdf";
            download_link.href = link_source;
            download_link.download = filename;
            download_link.click();
            resolve(true)
        } catch (error) {
            reject(false)
        }
    })
  }

  downloadPDF(pdf: string){
    return new Promise((resolve: any, reject: any) => {
        try {
            const link_source = pdf;
            const download_link = document.createElement("a");
            const filename = "cupon.pdf";
            download_link.href = link_source;
            download_link.target = '_blank';
            download_link.download = filename;

            document.body.appendChild(download_link);
            download_link.click();
            document.body.removeChild(download_link);
            resolve(true)
        } catch (error) {
            reject(false)
        }
    })
  }
  areEqualsValue(price: unknown, priceDiscount: unknown){
    let numberPrice = Number(price);
    let numberDiscountPrice = Number(priceDiscount)
    return numberPrice === numberDiscountPrice;
  }
  navTo() {
    const message =  `Hola, soy ${this.user.nombre } ${this.user.apellido} miembro del Club Fibex y te escribo para realizar el canjeo del código del cupón ${this.currentItem.name}, código ${this.currentItem.QR}. Me gustaría saber que pasos debo seguir para hacerlo efectivo `;
    this.helper.redirectToURL(this.item.LinkRedirect!,message);  
  }
  
}
