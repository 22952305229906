import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ClubFibexFeedingService } from '../../../../services/club-fibex-feeding.service';
import { CompanyInterface } from '../../../../interfaces/product.interface';
@Component({
  selector: 'app-list3',
  templateUrl: './list3.component.html',
  styleUrls: ['./list3.component.scss'],
})
export class List3Component implements OnInit {


  @Input() item: CompanyInterface;
  @Input() category: string = 'blue-new';
  constructor(
    private router: Router,
    public clubService: ClubFibexFeedingService
  ) { }

  ngOnInit() {}

  public goToCompanyProfile(companyId: string): void {
    this.router.navigate(['club-fibex', 'company-profile', companyId], { replaceUrl: false })
  }
}
