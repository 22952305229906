import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { MenuController, ModalController, Platform, PopoverController } from '@ionic/angular';
import { UserInterface } from '../../../interfaces/userInfo';
import { IUserSae, UserAuthenticationService } from '../../../services/user-authentication.service';
import { Router } from '@angular/router';
import { RoutingAppService } from '../../../services/routing-app.service';
import { FilterComponent } from '../filter/filter.page';
import { ProfileOptionsComponent } from '../ProfileOptions/ProfileOptions.component';
import { PushMessageListComponent } from '../push-message-list/push-message-list.component';
import { PushMessagesService } from '../../../services/push-messages.service';
import { SubscriberService } from '../../../core/services/subscriber.service';

@Component({
  selector: 'app-club-header',
  templateUrl: './club-header.component.html',
  styleUrls: ['./club-header.component.scss'],
})
export class ClubHeaderComponent implements OnInit {

  @Input() showSearchBar: boolean = false;
  @Input() showFilterBtn: boolean = false;
  @Input() showLightMode: boolean = false;
  @Input() canShowResultsSearcher: boolean = false
  @Output() navigateToHome: EventEmitter<void> = new EventEmitter<void>()
  @Output() goToSearchEmit: EventEmitter<void> = new EventEmitter<void>()
  @Output() handleSearchEmit: EventEmitter<Event> = new EventEmitter<Event>();
  
  public user: IUserSae | null;
  public NewMsg: boolean = false;
  public isIos: boolean = false;
  public isLoadingModal: boolean = false;
  public text: string = "";
  public isVisibleModalSearch: boolean = false;
  public isDesktop: boolean = true;
  constructor(
    private modalCtrl: ModalController,
    private router: Router,
    public athentication: UserAuthenticationService,
    private _routing: RoutingAppService,
    public _platform: Platform,
    private popoverController: PopoverController,
    public menssageSvc: PushMessagesService,
    private subscriber: SubscriberService,
    private menu: MenuController
  ) {
    this.user = this.athentication.getUser();
    this.isIos = this._platform.is('ios');
    // this.athentication.isAuthenticated$.subscribe((responseTypeAuthentitation: boolean)=>{
    //   this.isAuthenticatedExternal = responseTypeAuthentitation;
    // })
  } 
  public isAuthenticatedExternal: boolean = false;  
  ngOnInit() {
  
    this.isDesktop = this._platform.is('desktop');
    this.subscriber.subscriberButtonMenuDisabled$.subscribe(response=>{
    })
  }
  public goToClubIframe(): void {
    this.router.navigate(['club-fibex', 'club-iframe'])
  }

  public navegateHome(): void {
    this.navigateToHome.emit()
  }

  async openFilterModal(): Promise<void> {
    this.isLoadingModal = true;
    const modal = await this.modalCtrl.create({
      component: FilterComponent,
      cssClass: 'full-screen-modal ',
      mode: 'ios'
    })
    this.isLoadingModal =false;
    modal.present()
  }

  public goToSearch(): void {
    this.goToSearchEmit.emit()
  }

  public handleSearch(event: any): void {
    this.text = event.target.value;
    if(this.canShowResultsSearcher) {
     this.isVisibleModalSearch =this.text && this.text.length>0;
    }
    this.handleSearchEmit.emit(event);
  }

  goToChat() {
    this.NewMsg = false;
    this._routing.ChangeRuta("chat");
  }
  setValueModalSearchVisinel(value: boolean){
    this.isVisibleModalSearch = value;
  }
 async openModalOptions(event: any){
  const popover = await this.popoverController.create({
    component: ProfileOptionsComponent,
    cssClass: 'popover_small',
    event: event,
    translucent: true,
    mode: 'ios',
  });
    await popover.present();
  }

  toggleMenu() {
    this.menu.toggle()
    this.menu.toggle()
    this.menu.open()
    this.menu.enable(true);
  }
  async presentPushMessage(ev: any) {

    const OptionListProps: string[] = ['Solicitar', 'Soporte', 'Reclamo', 'Calificar'];

    const popover = await this.popoverController.create({
        component: PushMessageListComponent,
        cssClass: 'popover_small',
        event: ev,
        translucent: true,
        mode: 'ios',
        componentProps: { 'optionList': OptionListProps }
    });
    await popover.present();
}
}
