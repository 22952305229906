import * as tslib_1 from "tslib";
import { AfterViewChecked, AfterViewInit, ElementRef, OnInit, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Message, Button } from "../../../interfaces/customer-chatbox";
// import $ from "jquery";
import { environment } from '../../../../environments/environment';
import { FormBuilder, Validators } from "@angular/forms";
import { chatbot } from "./chatbot.metadata";
import { UserAuthenticationService } from "../../../services/user-authentication.service";
import { ModalController } from "@ionic/angular";
/**
 * CustomerChatbotComponent Documentation (mainly the Message object and related functions):
 *
 *  ********************************************************************************
 *  *  Message Box:                                          *  Related functions: *
 *  *--------------------------------------------------------*---------------------*
 *  *  message.content: body of text                         *  formatString()     *
 *  *--------------------------------------------------------*---------------------*
 *  *  message.form: forms the bot requests to be filled     *  createNewForm()    *
 *  *    --> formGroup                                       *  validateData()     *
 *  *    --> formControls                                    *                     *
 *  *      --> select: refers to <select>, contains the data *                     *
 *  *      --> (others)?: any other control can be added     *                     *
 *  *          through createNewForm() by setting a new case *                     *
 *  *--------------------------------------------------------*---------------------*
 *  *  message.images: images to be shown                    *                     *
 *  *--------------------------------------------------------*---------------------*
 *  *  message.buttons: buttons with choices                 *  selectOption()     *
 *  ********************************************************************************
 *
 *  Role of this component: convert what BotResponse returns to a component the user can interact with
 *
 */
export class ChatbotComponent {
    constructor(http, fb, authService, modalCtrl) {
        this.http = http;
        this.fb = fb;
        this.authService = authService;
        this.modalCtrl = modalCtrl;
        this.botAPI = environment.botAPI;
        this.sessionId = ""; // document.cookie and current datetime (current solution for getting an unique session id)
        // chatbox behaviour properties
        this.showChatbox = false; // refers to the entire chatbox
        this.isMinimized = false; // when true, only the .title is seen
        this.isWaitingForMessage = false; // shows the "user is typing..." kind of animation while user is waiting for bot to answer
        this.messages = [];
        this.messageInput = ""; // text message input
        this.externalButtonOpened = false;
        this.isAactiveButtnon = true;
        this.eventExternalOpened = new EventEmitter();
        this.user = authService.getUser();
    }
    ngOnInit() {
    }
    ngAfterViewChecked() {
        this.scrollToBottom(document.getElementById("chatbox-body"));
    }
    ngAfterViewInit() {
        this.sessionId = document.cookie + new Date();
        // this.chatInit()
        this.scrollToBottom(document.getElementById("chatbox-body"));
        if (this.externalButtonOpened) {
            this.chatInit();
        }
    }
    ngOnChanges(changes) {
        if (changes['externalButtonOpened'].currentValue === true) {
            this.chatInit();
        }
    }
    scrollToBottom(element) {
        if (element)
            element.scrollTo({ top: element.scrollHeight, behavior: 'smooth' });
    }
    /**
     * Open the chatbox and initialize chat with it's first messages
     */
    chatInit() {
        this.showChatbox = true;
        // create automatic bot message from the client
        let welcomeMessage = {
            from: "bot",
            content: "¡Hola! Soy tu Asistente Virtual y te doy la bienvenida a Club Fibex. Estoy para apoyarte en el uso de nuestra plataforma y sus funciones.",
            timeSent: this.getCurrentTime()
        };
        // instead of this.updateChat(), I add the message directly since I don't want to move the scrollbar when the chat is initializing
        this.messages.push(welcomeMessage);
        this.isWaitingForMessage = true; // start the loading animation
        // get first response from the bot API
        this.isWaitingForMessage = false;
    }
    /**
     * clear values from chat and essentially reset it
     */
    destroyChat(event) {
        if (this.externalButtonOpened)
            event.stopPropagation();
        this.messages = [];
        this.showChatbox = false;
        this.isMinimized = false;
        this.sessionId = document.cookie + new Date(); // update session id
        this.externalButtonOpened = false;
        this.eventExternalOpened.emit(false);
    }
    /**
     * When clicking the tray icon or the title, the chatbox will minimize
     */
    toggleMinimize() {
        this.isMinimized = !this.isMinimized;
    }
    /**
     * When chatbox open animation starts, adjust chatbox's body scrollbar so it's stuck to the bottom
     */
    afterChatboxIsOpen() {
        if (!this.isMinimized) { // when chatbox is being opened keep scrollbar stuck to the bottom
            let chatboxBody = document.getElementById("chatbox-body");
            if (chatboxBody) {
                chatboxBody.scrollTop = chatboxBody.scrollHeight;
            }
        }
    }
    /**
     * Send message to bot API and get response
     */
    sendMessage(option, message) {
        // let validationResult = this.validateData(); // should validate whenever there's a form present
        // if (validationResult.status === "INVALID") {
        //   this.updateChat({from: "bot", content: validationResult.message, timeSent: this.getCurrentTime()});
        //   return;
        // }
        let content = message;
        this.messageInput = ""; // clear <input />'s text
        this.updateChat({ from: "user", content, timeSent: this.getCurrentTime() });
        this.isWaitingForMessage = true; // start the loading animation
        // wait for the bot's response
        setTimeout(() => {
            this.isWaitingForMessage = false; // stop the loading animation
            this.updateChat(this.getBotResponse(option, message));
            // this.getBotResponse(option, message)
            // .then((botResponse: Message) => {
            //   this.isWaitingForMessage = false; // stop the loading animation
            //   this.updateChat(botResponse);
            // })
            // .catch((errorMessage: Message) => {
            //   this.isWaitingForMessage = false;
            //   this.updateChat(errorMessage); // message.error should be defined here
            // })
        }, 700);
        this.scrollToBottom(document.getElementById("chatbox-body"));
    }
    getBotResponse(option, message) {
        let postBody = {
            "phone": this.sessionId,
            "message": message,
            "lic": "584120202020@c.us",
            "application": "web"
        };
        const incomingMessage = chatbot.find((e, i) => i === +option);
        return {
            from: "bot",
            content: this.formatString(incomingMessage.message),
            timeSent: this.getCurrentTime()
        };
        // return new Promise((resolve, reject) => {
        //   this.http.post(this.botAPI, postBody).subscribe((data: BotResponse) => {
        //     if (data.status === "OK") {
        //       let incomingMessage = data.msg;
        //       if (!incomingMessage) {
        //         incomingMessage = "Disculpe no pude reconocer lo que quiso decir.";
        //       }
        //       let botMessage: Message = {
        //         from: "bot",
        //         content: this.formatString(incomingMessage),
        //         timeSent: this.getCurrentTime()
        //       };
        //       if (data.form != undefined) { // if data contains a form, create the means to display and manage it from the component
        //         botMessage.form = {
        //           formControls: data.form.formControls,
        //           formGroup: this.createNewForm(data.form.formControls)
        //         };
        //         this.currentlyActiveForm = botMessage.form.formGroup; // set the currently active form to do validations on
        //       }
        //       resolve(botMessage);
        //     } else {  
        //       reject({
        //         from: "bot",
        //         content: "Disculpe hubo error de conectividad con nuestro bot, intente comunicarse más tarde.",
        //         timeSent: this.getCurrentTime(),
        //         error: true
        //       } as Message);
        //     }
        //   }, (error) => {
        //     // for debugging I recomend logging this error inside this block
        //     reject({
        //       from: "bot",
        //       content: "Disculpe hubo un problema con nuestro bot, trataremos de solucionarlo lo mas pronto posible.",
        //       timeSent: this.getCurrentTime(),
        //       error: true
        //     } as Message);
        //   });
        // });
    }
    /**
     * Similar to sendMessage() but in the context of a <button> instead of <input />
     * @param option representation of <button> with the value it holds
     */
    selectOption(option) {
        // update chat with user's choice
        this.updateChat({ from: "user", content: option.value, timeSent: this.getCurrentTime() });
        this.isWaitingForMessage = true;
        // wait for the bot's response
        // this.getBotResponse(option.exec)
        // .then((botResponse: Message) => {
        //   this.isWaitingForMessage = false; // stop the loading animation
        //   this.updateChat(botResponse);
        // })
        // .catch((errorMessage: Message) => {
        //   this.isWaitingForMessage = false;
        //   this.updateChat(errorMessage); // message.error should be defined here
        // });
    }
    /**
     * Send to a server whether the customer was satisfied or not
     * @param isCustomerSatisfied represents the customer satisfaction with an answer the bot previously gave
     */
    sendCustomerSatisfaction(isCustomerSatisfied) {
        // send the rating to the server for it to deal with
    }
    updateChat(message) {
        this.messages.push(message);
        // keep the scrollbar stuck to the bottom
        // let chatboxBody = $("#chatbox-body");
        // if (this.chatboxBody) {
        //   setTimeout(() => {
        //     this.chatboxBody.animate({scrollTop: this.chatboxBody.prop("scrollHeight")}, 500);
        //   }, 100);
        // }
    }
    /**
     * Create a form group given the passed form controls
     * @param formControls object with set of data which holds what type of control it is for (defined by the object keys) and what data to fill it with
     * @returns the form group corresponding to the bot's message
     */
    createNewForm(formControls) {
        let controls = {};
        for (let controlType in formControls) {
            switch (controlType) {
                case "select":
                    controls[controlType] = ['', Validators.required]; // a <select> control should have this behaviour
                    break;
                // add more cases here in line with Message.form.formControls property names when the need arises
            }
        }
        return this.fb.group(controls);
    }
    /**
     * custom validation when bot's response contains data that must be validated
     * @returns
     */
    validateData() {
        let validationResult = { status: "VALID", message: "" };
        if (!this.currentlyActiveForm)
            return validationResult; // if there's no form there's no need to validate
        if (!this.currentlyActiveForm.invalid) {
            this.currentlyActiveForm = undefined; // clear the currently active form since validation gave a green light
            return validationResult;
        }
        // if invalid is true, check for validation
        let status = this.currentlyActiveForm.status; // see FormControlStatus for values
        validationResult.status = status;
        validationResult.message = "Por favor seleccione una opción válida.";
        return validationResult;
    }
    /*--------------------- Helper functions --------------------*/
    /**
     * get current hours and minutes
     * @returns string time in the form of hh:mm
     */
    getCurrentTime() {
        let date = new Date();
        return String(date.getHours()).padStart(2, "0") + ":" + String(date.getMinutes()).padStart(2, "0");
    }
    /**
     * format the string into something html can read and interpret
     * @param str
     * @returns formatted string with html
     */
    formatString(str) {
        str = str.replace(/\^\/.*$/g, ""); // remove the string that the server sends as ^{'object': 'foo'} (usually at the end)
        str = str.replace(/\n\n\n/g, "\n\n");
        str = str.replace(/\n\n/g, "\n");
        // detect bold text that comes in the form of *my text* and inject <b> tags
        let boldStringRegex = /\*.*\*/gi;
        let boldStringMatches = str.match(boldStringRegex);
        if (boldStringMatches) {
            for (let boldString of boldStringMatches) {
                let rawString = boldString.slice(1, -1); // remove the first and last character. Ex: *my text* -> my text
                str = str.replace(boldString, `<b>${rawString}</b>`);
            }
        }
        // detect links and add the <a> tag to them
        // let linkRegex = /(https?:\/\/(?:www\.|(?!www))[^\s\.]+\.[^\s]{2,}|www\.[^\s]+\.[^\s]{2,})/gi;
        // let linkMatches = str.match(linkRegex);
        // if(linkMatches) {
        //   for (let link of linkMatches) {
        //     str = str.replace(link, `<a target="_blank" href="${link}">${link}</a>`);
        //   }
        // }
        // detect emails and add the <a> tag to them
        // let emailRegex = /([a-zA-Z0-9._+-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/gi;
        // let emailMatches = str.match(emailRegex);
        // if(emailMatches) {
        //   for (let email of emailMatches) {
        //     str = str.replace(email, `<a href="mailto: ${email}">${email}</a>`);
        //   }
        // }
        return str;
    }
    /*-----------------------------------------------------------*/
    showCallMeModal() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            // const modal = await this.modalCtrl.create({
            //   component: CallMePage,
            //   componentProps: { DataClient: {cedula:this.user.cedula} },
            //   mode: 'ios'
            // });
            // await modal.present();
        });
    }
}
