import axios from "axios";
import CryptoJS from "crypto-js";

// Metodo GET **************************************
/**
 * Obtener todos los datos
 */
interface dbFullRequestGetFindAllInfo {
    type: "find-all-info"
}

/**
 * Obtener los datos, opcionalmente que cumplan con su respectivo campo y valor
 */
interface dbFullRequestGetFindAnyInfo<TypeData> {
    type: "find-any-info"
    campo?: keyof TypeData
    campo2?: keyof TypeData
    valor?: TypeData[keyof TypeData]
    valor2?: TypeData[keyof TypeData]
    limit?: number
    page?: number
}

/**
 * Buscar campos en dbfull (desconocido)
 */
interface dbFullRequestGetFindCampos {
    type: "find-campos"
}

/**
 * Buscar campos en dbfull (desconocido)
 */
interface dbFullRequestGetFindIn {
    type: "find-in"
}

/**
 * Buscar campos en dbfull (desconocido)
 */
interface dbFullRequestGetFindIn {
    type: "find-in"
}

/**
 * Buscar campos en dbfull (desconocido)
 */
interface dbFullRequestGetFindPagination {
    type: "find-pagination"
}

/**
 * Buscar campos en dbfull (desconocido)
 */
interface dbFullRequestGetFindBetweenDates {
    type: "find-between-dates"
}

/**
 * Buscar campos en dbfull (desconocido)
 */
interface dbFullRequestGetFindGreaterLower {
    type: "find-greater-lower"
}

/**
 * Buscar campos en dbfull (desconocido)
 */
interface dbFullRequestGetShowAllInfo {
    type: "show-all-info"
}

/**
 * Buscar campos en dbfull (desconocido)
 */
interface dbFullRequestGetShowAllInfoWithDB {
    type: "show-all-info-withbd"
}

/**
 * Utilizado por dbFullTable
 */
type dbFullRequestGetTable<TypeData> = (
    dbFullRequestGetFindAllInfo |
    dbFullRequestGetFindAnyInfo<TypeData> |
    dbFullRequestGetFindCampos |
    dbFullRequestGetFindIn |
    dbFullRequestGetFindPagination |
    dbFullRequestGetFindBetweenDates |
    dbFullRequestGetFindGreaterLower |
    dbFullRequestGetShowAllInfo |
    dbFullRequestGetShowAllInfoWithDB
)

/**
 * Utilizado por dbFullDataBase
 */
type dbFullRequestGetDb<TypeData> = dbFullRequestGetTable<TypeData> & {
    table: string
}

/**
 * Utilizado por dbFull
 */
type dbFullRequestGet<TypeData> = dbFullRequestGetDb<TypeData> & {
    db: string
}


// Metodo POST ******************************
/**
 * Insertar nuevo dato
 */
interface dbFullRequestPostCreateInfo<TypeData> {
    type: "create-info"
    "x-keys-to-add-id"?: (keyof TypeData)[]
    "x-keys-of-arrays"?: string[]
    "x-relations"?: boolean
}

/**
 * Insertar o modificar un campo
 */
interface dbFullRequestPostCreateOrFind<TypeData> {
    type: "create-or-find"
    "x-keys-to-add-id"?: (keyof TypeData)[]
    "x-keys-of-arrays"?: string[]
    "x-relations"?: boolean
    campo?: keyof TypeData
    campo2?: keyof TypeData
    valor?: TypeData[keyof TypeData]
    valor2?: TypeData[keyof TypeData]
    limit?: number
    page?: number
}

/**
 * Utilizado por dbFullTable
 */
type dbFullRequestPostTable<TypeData> = (
    dbFullRequestPostCreateInfo<TypeData> |
    dbFullRequestPostCreateOrFind<TypeData>
)

/**
 * Utilizado por dbFullDataBase
 */
type dbFullRequestPostDb<TypeData> = dbFullRequestPostTable<TypeData> & {
    table: string
}

/**
 *  Utilizado por dbFull
 */
type dbFullRequestPost<TypeData> = dbFullRequestPostDb<TypeData> & {
    db: string
}

// Metodo PUT **********************************
/**
 * Actualizar información de la base de datos
 */
interface dbFullRequestPutUpdateInfo<TypeData> {
    type: "update-info"
    "x-multiple-update"?: boolean,
    "x-elements-obj"?: string[],
    "x-attr-duplicate"?: string[],
}

// utilizado por dbFullTable
type dbFullRequestPutTable<TypeData> = (
    dbFullRequestPutUpdateInfo<TypeData>
)

// utilizado por dbFullDataBase
type dbFullRequestPutDb<TypeData> = dbFullRequestPutTable<TypeData> & {
    table: string
}

// utilizado por dbFull
type dbFullRequestPut<TypeData> = dbFullRequestPutDb<TypeData> & {
    db: string
}

// Metodo SELECT *********************************
interface dbFullRequestSelectTable<TypeData> {
    select: (keyof TypeData)[],
    where?: {[key in keyof TypeData]?: TypeData[key]}
}

type dbFullRequestSelectDb<TypeData> = (
    dbFullRequestSelectTable<TypeData> & {
        table: string
    }
)

type dbFullRequestSelect<TypeData> = dbFullRequestSelectDb<TypeData> & {
    db: string
}

// Metodo GET ANY QUERY *****************************
interface dbFullRequestGetAnyQuerysTable<TypeData> {
    query: string
}

type dbFullRequestGetAnyQuerys<TypeData> = (
    dbFullRequestGetAnyQuerysTable<TypeData> & {
        db: string
    }
)

// Metodo GET LENGTH
interface dbFullRequestGetLengthTable<TypeData> {
    where?: {[key in keyof TypeData]?: TypeData[key]}
}

interface dbFullRequestGetLengthDb<TypeData> extends dbFullRequestGetLengthTable<TypeData> {
    table: string
}

interface dbFullRequestGetLength<TypeData> extends dbFullRequestGetLengthDb<TypeData> {
    db: string
}

/**
 * Manejador General de dbFull
 */
export class dbFull {
    constructor() { }

    /**
     * Realiza una petición GET a los servidores de DbFull
     * @param request datos de la petición
     * @returns Retorna un array de los datos obtenidos
     */
    public async GET<TypeData>(request: dbFullRequestGet<TypeData>): Promise<TypeData[]> {
        const requestEncrypted = dbFull.EncrypObj(request);

        let headers: any = {
            TokenAuthPlataform: dbFull.__privateData.tokendbFull,
            Authorization: dbFull.__privateData.authdbFUll,
            ...requestEncrypted
        };

        const res = await axios.get(dbFull.__privateData.url, { headers: this.TransformObjectAnyToString(headers) });

        return res.data;
    }

    /**
     * Realiza una petición POST que te permite insertar nuevos elementos a una table
     * @param request datos de la petición
     * @param body cuerpo del dato a insertar
     * @returns Retorna el nuevo dato insertado
     */
    public async POST<TypeData>(request: dbFullRequestPost<TypeData>, body: {[key in keyof TypeData]?: TypeData[key]}): Promise<(TypeData)> {
        let {
            "x-keys-of-arrays": xKeysOfArray,
            "x-keys-to-add-id": xKeysToAddId,
            "x-relations": xRelations,
            type,
            ...data
        } = request;

        data = dbFull.EncrypObj(data) as typeof data;

        const headers = {
            TokenAuthPlataform: dbFull.__privateData.tokendbFull,
            Authorization: dbFull.__privateData.authdbFUll,
            "x-keys-of-arrays": xKeysOfArray || [],
            "x-keys-to-add-id": xKeysToAddId || [],
            "x-relations": xRelations || false,
            ...data
        }

        const res = await axios.post(dbFull.__privateData.url + type, body, { headers: this.TransformObjectAnyToString(headers) });

        return res.data;
    }

    /**
     * Actualiza un dato ya existente en base de datos
     * @param request datos de la petición
     * @param body datos a actualizar (Todos no son obligatorios)
     * @returns Retorna la colunma actualizada (en la base de datos)
     */
    public async PUT<TypeData>(request: dbFullRequestPut<TypeData>, body: { [key in keyof TypeData]?: TypeData[key] }) {
        let {
            "x-multiple-update": xMultipleUpdate,
            "x-elements-obj": xElementsObj,
            "x-attr-duplicate": xAttrDuplicate,
            type,
            ...data
        } = request;

        data = dbFull.EncrypObj(data) as typeof data;

        const headers = {
            TokenAuthPlataform: dbFull.__privateData.tokendbFull,
            Authorization: dbFull.__privateData.authdbFUll,
            "x-multiple-update": JSON.stringify(xMultipleUpdate || false),
            "x-elements-obj": JSON.stringify(xElementsObj || []),
            "x-attr-duplicate": JSON.stringify(xAttrDuplicate || []),
            ...data
        }

        const res = await axios.put(dbFull.__privateData.url + type, body, { headers: this.TransformObjectAnyToString(headers) });

        return res.data;
    }

    /**
     * Permite realizar un query de manera aleatoria, siempre y cuando esta séa una expresión **SELECT**
     * @param request datos de la petición
     * @returns Retorna un resultado al azar según el query (NOTA: Tener cuidado)
     */
    public async GET_ANY_QUERY<TypeData>(request: dbFullRequestGetAnyQuerys<TypeData>): Promise<TypeData[]> {
        const headers = {
            TokenAuthPlataform: dbFull.__privateData.tokendbFull,
            Authorization: dbFull.__privateData.authdbFUll,
            db : dbFull.encrypt(request.db),
            type: dbFull.encrypt("any-queries"),
            "x-data-query": dbFull.encrypt(request.query || ""),
        }

        try {
            const res = await axios.get(dbFull.__privateData.url, { headers: this.TransformObjectAnyToString(headers) });
            return res.data;
        }
        catch(err) {
            console.log((err as any).response.data);
            throw("Error de get any query")
        }
    }

    /**
     * Permite obtener la longitud de elementos encontrados, según séa la condición
     * @param request datos de la petición
     * @returns Retorna la longitud encontrada en base de datos
     */
    public async GET_LENGTH<TypeData extends {}>(request: dbFullRequestGetLength<TypeData>): Promise<number> {
        let query: string;

        if(request.where && Object.keys(request.where).length) {
            const whereQuery = Object.keys(request.where).map((key) => `${key}=${JSON.stringify((request.where as any)[key])}`).join(" && ");

            query = `SELECT COUNT(*) FROM ${request.table} WHERE ${whereQuery}`;
        }
        else {
            query = `SELECT COUNT(*) FROM ${request.table}`;
        }

        const result = await this.GET_ANY_QUERY({db: request.db, query});
        const count = (result.find(item => ("COUNT(*)" in (item as any))) as any) && (result.find(item => ("COUNT(*)" in (item as any))) as any)["COUNT(*)"];

        if(typeof count === "number") return count;
        else throw(new Error("No se logró obtener la longitud de la tabla: " + request.table + ", db: " + request.db));
    }

    /**
     * Permite obtener sólamente los miembros necesarios de una tabla, según la condición
     * @param request datos de la petición
     * @returns Retorna los datos obteniidos, según la condición
     */
    public async SELECT<TypeData extends {}>(request: dbFullRequestSelect<TypeData>): Promise<{[key in keyof TypeData]?: TypeData[key]}[]> {
        let query: string;

        if(request.select && request.select.length) {
            const selectQuery = request.select.filter((item, index) => request.select.slice(index+1).every(item2 => item !== item2)).join(", ");

            if(request.where && Object.keys(request.where).length) {
                const whereQuery = Object.keys(request.where).map(key => `${key}=${JSON.stringify((request.where as any)[key])}`).join(" && ");
    
                query = `SELECT ${selectQuery} FROM ${request.table} WHERE ${whereQuery}`;
            }
            else {
                query = `SELECT ${selectQuery} FROM ${request.table}`
            }
        }
        else {
            if(request.where && Object.keys(request.where).length) {
                const whereQuery = Object.keys(request.where).map(key => `${key}=${JSON.stringify((request.where as any)[key])}`).join(" && ");
    
                query = `SELECT * FROM ${request.table} WHERE ${whereQuery}`;
            }
            else {
                query = `SELECT * FROM ${request.table}`
            }
        }


        return await this.GET_ANY_QUERY<any>({db: request.db, query: query});
    }

    /**
     * Permite crear una subdivición del DbFull, que sólo pueda manejar una base de datos, y sus respectivas tablas (el **dbFullDataBase** generado
     * no tiene permitido acceder a otras base de datos pertenecientes a dbFull)
     * @param nameDB base de datos
     * @returns dbFull dedicado a manejar una base de datos específica
     */
    public CreateChild(nameDB: string): dbFullDataBase {
        return dbFullDataBase.CreateByParent(nameDB, this);
    }

    /**
     * Transforma todos los miembros de un objeto en texto **(string)**
     * @param objTransform Objeto a transformar
     * @returns Un objeto con todas las variables miembros de tipo **string**
     */
    private TransformObjectAnyToString<Type extends {}>(objTransform: Type): {[key in keyof Type]: string} {
        const objMaped: {[key in keyof Type]: string} = {} as any;

        for(let keyObj in objTransform) {
            objMaped[keyObj] = typeof objTransform[keyObj] === "string" ? String(objTransform[keyObj]) : JSON.stringify(objTransform[keyObj]);
        }

        return objMaped;
    }

    /**
     * Permite encriptar un texto
     * @param str texto a encriptar
     * @returns texto encriptado
     */
    private static encrypt(str: string): string {
        let encrypted = CryptoJS.AES.encrypt(str, this.__privateData.key, {
          keySize: 16,
          mode: CryptoJS.mode.ECB,
          padding: CryptoJS.pad.Pkcs7,
        });
        return encrypted.toString();
    }

    /**
     * Permite desencriptar un texto encriptado
     * @param str texto a desencriptar
     * @returns Texto desencriptado
     */
    private static decrypt(str: string): string {
      let decrypted = CryptoJS.AES.decrypt(str, this.__privateData.key, {
        keySize: 16,
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7,
      }).toString(CryptoJS.enc.Utf8);

      return decrypted.toString()
    }

    /**
     * Encripta un objeto (se permite aplicar recursividad; objeto dentro de otro objeto) 
     * @param obj Objeto a encriptar
     * @returns Retorna una copia del objeto encriptado
     */
    private static EncrypObj<TypeObj extends {}>(obj: {[key in keyof TypeObj]: string | typeof obj}): typeof obj {
        const newObj: any = {};

        for(let keyName in obj) {
            if(obj[keyName] instanceof Object) {
                obj[keyName] = this.EncrypObj(obj[keyName] as typeof obj);
            }
            else newObj[keyName] = this.encrypt(String(obj[keyName]));
        }
        return newObj;
    }

    /**
     * No se recomienda que esto esté aqui, (debe estar en el environment) pero si esto está aqui, permite que este fichero séa totalmente
     * idependiente
     */
    private static __privateData = {
        url: "https://dbfull.thomas-talk.me/api/",
        authdbFUll: 'Basic ' + btoa('Onur:L4V1d43NsuPl3N1tud**=BghYjLaQeTB'),
        key: 'T0rNaDoK4tr1Na?RTgcNmhKU=',
        tokendbFull: '81N2vjsIqq39qjGoEmDmMtjLqW7gLDA7vBV-Ffwuhwf-evejDaRGMrdSASny480GVOl7fcYfh21xfcpJWZ8VzQBHf0chPGOhyo9w3zJQ8OXEYGxwzxCU1gDplt3ebE4wDCkoujh4284bTkzz52AbNudtcR1HBq5_xU3mL5IJ4pqbeiFOJVa9',
    }
}

/**
 * Subdivición de **class dbFull**, dedicado a manejar una única base de datos
 */
export class dbFullDataBase extends dbFull {
    protected parent: dbFull | null;

    /** 
     * Crea una instancia, asociado a su padre (esto es usado por **class dbFull**)
    */
    public static CreateByParent(db: string, parent: dbFull) {
        const dbDataBase = new dbFullDataBase(db);
        dbDataBase.parent = parent;

        return dbDataBase;
    }

    /**
     * Constructor
     * @param db base de datos a manejar
     */
    constructor(public db: string) {
        super();

        this.parent = null;
    }

    /**
     * Realiza una petición GET a los servidores de DbFull
     * @param request datos de la petición
     * @returns Retorna un array de los datos obtenidos
     */
    public async GET<TypeData>(request: dbFullRequestGetDb<TypeData>) {
        return await super.GET<TypeData>({...request, db: this.db});
    }

    /**
     * Realiza una petición POST que te permite insertar nuevos elementos a una table
     * @param request datos de la petición
     * @param body cuerpo del dato a insertar
     * @returns Retorna el nuevo dato insertado
     */
    public async POST<TypeData>(request: dbFullRequestPostDb<TypeData>, body: {[key in keyof TypeData]?: TypeData[key]}) {
        return await super.POST<TypeData>({...request, db: this.db}, body);
    }

    /**
     * Actualiza un dato ya existente en base de datos
     * @param request datos de la petición
     * @param body datos a actualizar (Todos no son obligatorios)
     * @returns Retorna la colunma actualizada (en la base de datos)
     */
    public async PUT<TypeData>(request: dbFullRequestPutDb<TypeData>, body: {[key in keyof TypeData]?: TypeData[key]}) {
        return await super.PUT<TypeData>({...request, db: this.db}, body);
    }

    /**
     * Permite realizar un query de manera aleatoria, siempre y cuando esta séa una expresión **SELECT**
     * @param request datos de la petición
     * @returns Retorna un resultado al azar según el query (NOTA: Tener cuidado)
     */
    public async GET_ANY_QUERY<TypeData>(request: dbFullRequestGetAnyQuerysTable<TypeData>) {
        return await super.GET_ANY_QUERY<TypeData>({db: this.db, query: request.query});
    }

    /**
     * Permite obtener la longitud de elementos encontrados, según séa la condición
     * @param request datos de la petición
     * @returns Retorna la longitud encontrada en base de datos
     */
    public async GET_LENGTH<TypeData>(request: dbFullRequestGetLengthDb<TypeData>): Promise<number> {
        return await super.GET_LENGTH({...request, db: this.db});
    }

    /**
     * Permite obtener sólamente los miembros necesarios de una tabla, según la condición
     * @param request datos de la petición
     * @returns Retorna los datos obteniidos, según la condición
     */
    public async SELECT<TypeData extends {}>(request: dbFullRequestSelectDb<TypeData>): Promise<{[key in keyof TypeData]?: TypeData[key]}[]> {
        return await super.SELECT<TypeData>({...request, db: this.db}); 
    }

    /**
     * Permite crear una subdivición del DbFull, que sólo pueda manejar una base de datos, y sus respectivas tablas (el **dbFullDataBase** generado
     * no tiene permitido acceder a otras base de datos pertenecientes a dbFull)
     * @param nameDB base de datos
     * @returns dbFull dedicado a manejar una base de datos específica
     */
    public GetParent() {
        return this.parent || new dbFull();
    }

    /**
     * Permite crear una subdivición que tenga constrol sobre sí mismo a una tabla
     * @param nameTable nombre de la tabla
     * @returns retorna una instancia de la subdivición creada
     */
    public CreateChild<TypeData>(nameTable: string): dbFullTable<TypeData> {
        return dbFullTable.CreateByParent<TypeData>(nameTable, this);
    }
}

/**
 * Subdivición de **class dbFullDataBase**, dedicado a manejar una única tabla perteneciente a su base de datos respectivo
 */
export class dbFullTable<TypeData> extends dbFullDataBase {
    // protected parent: dbFull;
    public parent: dbFullDataBase | null;

    public static CreateByParent<TypeData>(table: string, parent: dbFullDataBase) {
        const dbDataBase = new dbFullTable<TypeData>(parent.db, table);
        dbDataBase.parent = parent;

        return dbDataBase;
    }

    constructor(db: string, public table: string) {
        super(db)

        this.parent = null;
    }

    public async GET<Type = TypeData>(request: dbFullRequestGetTable<Type>) {
        return await super.GET<Type>({...request, table: this.table});
    }

    public async POST<Type = TypeData>(request: dbFullRequestPostTable<Type>, body: {[key in keyof Type]?: Type[key]}) {
        return await super.POST<Type>({...request, table: this.table}, body);
    }

    public async PUT<Type = TypeData>(request: dbFullRequestPutTable<Type>, body: {[key in keyof Type]?: Type[key]}) {
        return await super.PUT<Type>({...request, table: this.table}, body);
    }

    public async GET_ANY_QUERY<Type = TypeData>(request: dbFullRequestGetAnyQuerysTable<Type>): Promise<Type[]> {
        return await super.GET_ANY_QUERY<Type>(request);
    }

    public async GET_LENGTH<Type = TypeData>(request: dbFullRequestGetLength<Type>): Promise<number> {
        return await super.GET_LENGTH({...request, table: this.table});
    }

    public async SELECT<Type extends {} = TypeData & {}>(request: dbFullRequestSelectTable<Type>): Promise<{[key in keyof Type]?: Type[key]}[]> {
        return await super.SELECT<Type>({...request, table: this.table})
    }

    public GetParent(): dbFullDataBase {
        return this.parent || new dbFullDataBase(this.db);
    }
}
